import { NAME as categoriesReducerName } from '@spa-core/store/categories/constants'
import { CategoriesStore } from '@spa-core/store/categories/interfaces'
import { reducer as categoriesReducer } from '@spa-core/store/categories/reducer'
import { watchers as categoriesWatchers } from '@spa-core/store/categories/sagas'

import { NAME as navigationReducerName } from '@spa-core/store/navigation/constants'
import { NavigationStore } from '@spa-core/store/navigation/interfaces'
import { reducer as navigationReducer } from '@spa-core/store/navigation/reducer'

import { NAME as productsReducerName } from '@spa-core/store/products/constants'
import { ProductsStore } from '@spa-core/store/products/interfaces'
import { reducer as productsReducer } from '@spa-core/store/products/reducer'
import { watchers as productsWatchers } from '@spa-core/store/products/sagas'

import { NAME as breadcrumbsReducerName } from '@spa-core/store/breadcrumbs/constants'
import { BreadcrumbsStore } from '@spa-core/store/breadcrumbs/interfaces'
import { reducer as breadcrumbsReducer } from '@spa-core/store/breadcrumbs/reducer'
import { watchers as breadcrumbsWatchers } from '@spa-core/store/breadcrumbs/sagas'

import { NAME as globalMessagesReducerName } from '@spa-core/store/global-messages/constants'
import { GlobalMessagesStore } from '@spa-core/store/global-messages/interfaces'
import { reducer as globalMessagesReducer } from '@spa-core/store/global-messages/reducer'
import { watchers as globalMessagesWatchers } from '@spa-core/store/global-messages/sagas'

import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { AppStore } from '@spa-core/store/app/interfaces'
import { reducer as appReducer } from '@spa-core/store/app/reducer'
import { watchers as appWatchers } from '@spa-core/store/app/sagas'

import { NAME as cartReducerName } from '@spa-core/store/cart/constants'
import { CartStore } from '@spa-core/store/cart/interfaces'
import { reducer as cartReducer } from '@spa-core/store/cart/reducer'
import { watchers as cartWatchers } from '@spa-core/store/cart/sagas'

import { NAME as customerFeedbackReducerName } from '@spa-core/store/customer-feedback/constants'
import { CustomerFeedbackStore } from '@spa-core/store/customer-feedback/interfaces'
import { reducer as customerFeedbackReducer } from '@spa-core/store/customer-feedback/reducer'
import { watchers as customerFeedbackWatchers } from '@spa-core/store/customer-feedback/sagas'

import { NAME as historicalLowPriceReducerName } from '@spa-core/store/historical-low-price/constants'
import { HistoricalLowPriceStore } from '@spa-core/store/historical-low-price/interfaces'
import { reducer as historicalLowPriceReducer } from '@spa-core/store/historical-low-price/reducer'
import { watchers as historicalLowPriceWatchers } from '@spa-core/store/historical-low-price/sagas'

import { NAME as searchReducerName } from '@spa-core/store/search/constants'
import { SearchStore } from '@spa-core/store/search/interfaces'
import { reducer as searchReducer } from '@spa-core/store/search/reducer'
import { watchers as searchWatchers } from '@spa-core/store/search/sagas'

import { NAME as campaignReducerName } from '@spa-core/store/campaign/constants'
import { CampaignStore } from '@spa-core/store/campaign/interfaces'
import { reducer as campaignReducer } from '@spa-core/store/campaign/reducer'
import { watchers as campaignWatchers } from '@spa-core/store/campaign/sagas'

export interface Store {
    modules: any
    reducers: {
        [categoriesReducerName]: CategoriesStore
        [productsReducerName]: ProductsStore
        [breadcrumbsReducerName]: BreadcrumbsStore
        [globalMessagesReducerName]: GlobalMessagesStore
        [cartReducerName]: CartStore
        [customerFeedbackReducerName]: CustomerFeedbackStore
        [historicalLowPriceReducerName]: HistoricalLowPriceStore
        [searchReducerName]: SearchStore
        [navigationReducerName]: NavigationStore
        [campaignReducerName]: CampaignStore
        [appReducerName]: AppStore
    }
    serviceData: any
}

export const reducers = {
    [categoriesReducerName]: categoriesReducer,
    [productsReducerName]: productsReducer,
    [breadcrumbsReducerName]: breadcrumbsReducer,
    [globalMessagesReducerName]: globalMessagesReducer,
    [cartReducerName]: cartReducer,
    [customerFeedbackReducerName]: customerFeedbackReducer,
    [historicalLowPriceReducerName]: historicalLowPriceReducer,
    [searchReducerName]: searchReducer,
    [navigationReducerName]: navigationReducer,
    [campaignReducerName]: campaignReducer,
    [appReducerName]: appReducer,
}

export const watchers = [
    ...categoriesWatchers,
    ...productsWatchers,
    ...breadcrumbsWatchers,
    ...globalMessagesWatchers,
    ...cartWatchers,
    ...customerFeedbackWatchers,
    ...historicalLowPriceWatchers,
    ...searchWatchers,
    ...campaignWatchers,
    ...appWatchers,
]
