import { ActionTypes } from './constants'
import { NavigateToPayload, RedirectToPayload } from './interfaces'

export const redirectTo = (payload: RedirectToPayload) => ({
    type: ActionTypes.REDIRECT_TO,
    payload,
})

export const navigateTo = (payload: NavigateToPayload) => ({
    type: ActionTypes.NAVIGATE_TO,
    payload,
})

export const resetNavigation = () => ({
    type: ActionTypes.RESET,
})
