import logger from '@spa-core-js/services/logSvc'
import { triggerReducerReplacement } from '@spa-core-js/redux/store'
import { injectState, rawInjectState, extractStyles, isPuppeteer } from '@spa-core-js/util/extractStyles'
import forEach from 'lodash/forEach'
import logUrl from '@spa-core-js/services/logSvc/request'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { CookiebotUtil } from '../utils/CookiebotUtil'

const log = logger.getLogger('Analytics Middleware'); // eslint-disable-line

/*
    Disabled according to: https://madhat-jira.atlassian.net/browse/IC-18183?atlOrigin=eyJpIjoiYjFkNGFmNWNkOGQ1NGUxZDkwMDVlYzRmZThmNzc0ODciLCJwIjoiaiJ9
    if (sessionConf.isSPA && !isPuppeteer && !isVTT) {
        logAdBlockerStatus();
    }
*/

let spaSessionSent = false
const cookiebotUtil: CookiebotUtil = new CookiebotUtil()

const analytics = (store) => (next) => (action) => {
    if (!store) {
        return
    }
    const sessionConfig = store?.reducers?.[sessionReducerName]?.sessionConfig || window['sessionConf']
    if (!sessionConfig?.isSPA) {
        next(action)
        return
    }

    if (!spaSessionSent && !isPuppeteer && window?.['ga'] && cookiebotUtil.hasStatisticsConsent) {
        spaSessionSent = true
        window['ga']('send', {
            hitType: 'event',
            eventCategory: 'SESSION',
            eventAction: 'SPA_SESSION',
            eventLabel: 'SPA_SESSION',
            nonInteraction: true,
        })
    }
    // Only spa sites left at this point

    // Triggers replace reduicers if there are pending reducers to add
    // Todo: create utility middleware and move this call there
    triggerReducerReplacement(action.type)

    // save state to the page for the client but wait until there is some idle in the events
    if (isPuppeteer) {
        injectState()
        extractStyles()
        // Test
        setTimeout(() => {
            // Take no changes update the state every 0.250 seconds
            rawInjectState()
            extractStyles()
        }, 250)
    }
    if (action) {
        // Generic third party check
        log.debug('Action ', action.type, action)
        // Todo, move this into third-arty-middleware
        if (sessionConfig?.thirdParties?.[action.type]) {
            forEach(sessionConfig.thirdParties[action.type], (fun) => {
                // Call the configured fuction for the event
                try {
                    logUrl('event', 'REG_EVENT')
                    fun(action)
                } catch (err) {
                    logUrl('event', 'REG_EVENT_ERROR')
                    log.error('Errorin regEvent function:', err)
                }
            })
        }
        // Check user consent
        if (!cookiebotUtil.hasStatisticsConsent) {
            next(action)
            return
        }
        next(action)
    }
}

export default analytics
