import PropTypes from 'prop-types';
import React from 'react';
import SlideInOut from 'ui/SlideInOut';
import CleanText from 'ui/CleanText';
import classNames from 'classnames';
import Icon, { Icons } from '@ui-elem/Icon/Icon';

const Content = (p) => (
  <div className={'ic-accordion-item'} key={'content'}>
    {p.cleanText ? <CleanText value={p.children} /> : p.children}
  </div>
);

export default class AccordionItem extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      className: PropTypes.string,
      children: PropTypes.node,
      activeItem: PropTypes.array,
      accIndex: PropTypes.any,
      title: PropTypes.string,
      titleSize: PropTypes.string,
      subTitle: PropTypes.string,
      expandedSubTitle: PropTypes.string,
      open: PropTypes.func,
      close: PropTypes.func,
    };
  }

  render() {
    const _expanded = this.props.activeItem ? this.props.activeItem.indexOf(this.props.accIndex) !== -1 : false;
    const expanded = this.props.initialized ? _expanded : this.props.openFromStart;
    const handler = expanded ? this.props.close : this.props.open;
    return this.props.content !== '' && this.props.title !== '' ? (
      <div
        data-ssr-key={this.props.SSRKey}
        key={'acc' + this.props.accIndex}
        id={'acc-' + this.props.accIndex}
        className={classNames(this.props.className, 'smartEditComponent')}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
      >
        <div
          className={'ic-accordion-title p-2 mb-1 cursor-pointer col-panel'}
          key={'title'}
          onClick={() => {
            handler(this.props.accIndex);
          }}
        >
          <div className={'flex justify-between title-row print:hidden'}>
            {expanded && this.props.expandedSubTitle ? (
              <div className={'flex items-center'}>
                <h4 className="my-0">{this.props.title}</h4>
                <div className={'italic col-secondary-fg ml-1'}>{this.props.expandedSubTitle}</div>
              </div>
            ) : (
              <div className={'flex items-center'}>
                <h4 className={classNames(this.props.titleSize ? 'text-' + this.props.titleSize : '', 'my-0')}>
                  {this.props.title}
                </h4>
                <div className={'italic col-secondary-fg ml-1'}>{this.props.subTitle}</div>
              </div>
            )}
            <Icon
              icon={Icons.AngleRight}
              rotate90={expanded}
              className={classNames('self-center e2e-ver-accordion-icon', 'col-' + this.props.iconColor)}
            />
          </div>
        </div>

        <SlideInOut isOpened={expanded} duration={300}>
          <Content {...this.props} />
        </SlideInOut>
      </div>
    ) : null;
  }
}

AccordionItem.defaultProps = {
  expanded: false,
  subTitle: '',
  accIndex: [],
};
