import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import net from 'score/networkSvc';
import logger from 'score/logSvc';
import browserSvc from 'score/browserSvc';
import serverPreLoad from 'seco/localeSvc/serverPreLoad';
import getStore from '@spa-core/redux/store';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const log = logger.getLogger('siteLocaleSvc');

export class siteLocaleSvc extends React.Component {
  constructor() {
    super();
    this.stringsDb = {};
  }

  /**
   * Initializing the locale service for a specific store. In practive this includes fetching
   * the translations from the server and adding them to this specific store. It might be
   * more correct to say that the the locale service initialized the store and the stae with
   * correct language strings.
   * @param {Object} _store - The store for the session.
   */
  init(_store) {
    if (!_store) {
      return;
    }

    if (browserSvc.inBrowser()) {
      // Only fetch the strings via the webservice if the strings ws not provided by the serverside rendering already
      if (!_store.getState()?.serviceData?.siteLocaleSvc?.strings) {
        this.fetchData(_store);
      }
    } else {
      // On server side - read data form file and store in state
      const state = _store.getState();
      // The value originates from the server side where it was passed to the server side rendering function
      // by the react tag then the locale is set into the state by the reducer (still at the server side).
      const siteLocale = state.serviceData.siteLocaleSvc.siteLocale;

      if (!this.stringsDb[siteLocale]) {
        const pathToUi = '../../../custom/inkclub/inkclubstorefront/web/webroot/_ui';
        const siteLocaleObj = serverPreLoad(`${pathToUi}/dist/siteLocale/site-${siteLocale}.json`);
        _store.dispatch({
          type: 'SITE_LOCALE_STRINGS',
          data: siteLocaleObj,
          siteLocale,
          noAnalytics: true,
        });
        this.stringsDb[siteLocale] = siteLocaleObj;
      } else {
        _store.dispatch({
          type: 'SITE_LOCALE_STRINGS',
          data: this.stringsDb[siteLocale],
          siteLocale,
          noAnalytics: true,
        });
      }
    }
  }

  /**
   * Function that gets all the strings and properties according to the predefined rules for a specific locale. If no
   * locale is provided the locale provided by hybris is used.
   * @param {Object} store - The redux store for the session.
   */
  fetchData(store) {
    let strings;
    const sessionConfig = getReducer(sessionReducerName)?.sessionConfig || window.sessionConf;
    const siteName = sessionConfig.siteName || '';
    const siteNameLower = siteName.toLowerCase();
    const siteLocaleKey = `${siteNameLower}_${sessionConfig.currentLanguageIsocode}`;
    try {
      strings = JSON.parse(browserSvc.sessionGet(`LOCALE_STRINGS_${siteLocaleKey}`));
    } catch (exception) {
      strings = null;
    }

    const self = this;
    const fetcher = (res) => {
      try {
        try {
          browserSvc.sessionSet(`LOCALE_STRINGS_${siteLocaleKey}`, JSON.stringify(res));
        } catch (exc) {
          log.debug(exc);
        }
        self.stringsDb[siteLocaleKey] = res;
        store.dispatch({
          type: 'SITE_LOCALE_STRINGS',
          data: res,
          siteLocale: siteLocaleKey,
          noAnalytics: true,
        });
      } catch (err) {
        log.error('Error: ', err);
      }
    };

    if (strings === null) {
      net.get(`${sessionConfig.contextPath}/_ui/dist/siteLocale/site-${siteLocaleKey}.json`).then(fetcher);
    } else {
      this.stringsDb[siteLocaleKey] = strings;
      store.dispatch({
        type: 'SITE_LOCALE_STRINGS',
        data: strings,
        siteLocale: siteLocaleKey,
        noAnalytics: true,
      });
    }
  }

  /**
   * Function that formats a string as per the defined property from siteLocaleSvc
   * @param {Object} store - the redux store
   * @param {string} strKey - key of the property
   * @param {string[]} args - Arguments that the property takes
   * @returns {string} A translated string containing the argumented property value.
   * If no stranslation was fount the identifier is returned. If no translations at all
   * was found an empty string is returned.
   */
  toStr(store, strKey, args) {
    if (!store || !store.getState) {
      return '';
    }
    // get the state fromt he store
    const state = store.getState();

    // At this point at state has been extracted fromt he arguments or the current object
    const strings = state.serviceData.siteLocaleSvc.strings;

    // Find the translation for the string identifier
    let rawString = '';
    if (strings) {
      if (strings[strKey]) {
        rawString = strings[strKey];
      } else {
        // No translation is found, return the original identifier
        return strKey;
      }
    } else {
      // No object with translations has been found return an empty string instead
      // of the original identifer. This avoid the whole gui to be messed up with odd strings
      return '';
    }

    // Check if some arguments are to be added to the string
    if (!args) {
      return rawString;
    }

    // Check the arguments and replace the placeholders with values
    if (isArray(args)) {
      forEach(args, (argument, i) => {
        const reStr = `\\{${i}\\}`;
        const re = RegExp(reStr, 'g');
        if (isNumber(args[i]) || isString(args[i])) {
          rawString = rawString.replace(re, args[i]);
        } else {
          rawString = rawString.replace(re, '[Object]');
        }
      });
    }

    return rawString;
  }
}

siteLocaleSvc.contextTypes = {
  store: PropTypes.object,
};
const service = new siteLocaleSvc();

export default service;

export const siteLocaleStr = (strKey, args) => service.toStr(getStore(), strKey, args);
