import React from 'react'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes, MessageLevels } from './constants'
import { str } from '@spa-ec-js/services/localeSvc'
import { AddMessage, RestCallFailed } from './interfaces'
import { HttpMethods } from '@spa-core/constants/HttpMethods'
import logger from '@spa-core-js/services/logSvc'
import styled from 'styled-components'
import { getThemeFactory } from '@ic-theme'
import { HttpStatusCode } from 'axios'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { addMessage } from './actions'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'

const name: string = 'GlobalMessages'
const getId = (id: number | string): number | string => id || new Date().getTime()

export function* addRestCallFailedMessage({ payload }: any) {
    const { url, method, requestError, error }: RestCallFailed = payload

    if (requestError?.status === HttpStatusCode.Ok) {
        return
    }

    const id: number | string = getId(url)
    const theme: any = getThemeFactory(name, {})

    const TechincalInfo = styled.div`
        &&& {
            color: #666666;
            padding-top: ${theme.space}rem;
        }
    `

    const message: React.ReactNode = (
        <div>
            {str('phrase.network.error.title')} <Icon icon={Icons.SadTear} className={'pl-1'} />
            <br />
            {__DEV__ ? (
                <TechincalInfo theme={theme()}>
                    Techincal information: <br />
                    Url: {url} <br />
                    Method: {method}
                    <br />
                    Result code: {requestError?.status}
                    <br />
                    Result text: {requestError?.statusText || error?.message}
                    <br />
                </TechincalInfo>
            ) : null}
        </div>
    )

    const messagePayload: AddMessage = {
        title: str('phrase.network.error.title'),
        message,
        level: MessageLevels.ERROR,
        id,
        statusCode: requestError?.status,
    }

    yield put({
        type: ActionTypes.ADD_MESSAGE,
        payload: messagePayload,
    })

    const log = logger.getLogger(name)
    log.error(
        str('phrase.network.error.title'),
        'Techincal information: ',
        'Url:',
        url,
        'Method:',
        method,
        'Result code:',
        requestError?.status,
        'Result text:',
        requestError?.statusText || error?.message,
    )
}

export function* fetchGlobalMessageFromServer({ payload }: any) {
    const { messageKey } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.urlPrefix + '/rest/v1/message/global/' + messageKey + '/'
    try {
        const result = yield call(() => net.get(url))
        let title: string = str(result.content.title)
        // Ignore an undefiend title
        if (title === result.content.title) {
            title = ''
        }
        if (result.content) {
            yield put(
                addMessage({
                    title,
                    message: str(result.content.message),
                    level: result.content.type,
                    id: messageKey,
                    displaySeconds: 5,
                }),
            )
        }
    } catch (requestError) {
        const payload: RestCallFailed = {
            url,
            method: HttpMethods.GET,
            requestError,
        }
        put({
            type: ActionTypes.ADD_REST_CALL_FAILED_MESSAGE,
            payload,
        })
    }
}

export function* fetchAllGlobalMessageFromServer() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.urlPrefix + '/rest/v1/message/global/all'
    try {
        const result: any = yield call(() => net.get(url))
        const actions: any[] = []
        Object.entries(result.content).forEach((e) => {
            const gMessage: any = e[1]
            let title: string = str(gMessage.title)
            // Ignore an undefined title
            if (title === gMessage.title) {
                title = ''
            }
            const id: number | string = e[0]
            const level: MessageLevels = gMessage.type
            const payload: AddMessage = {
                title,
                message: gMessage.title,
                level,
                id,
            }
            if (gMessage) {
                actions.push({
                    type: ActionTypes.ADD_MESSAGE,
                    payload,
                })
            }
        })
        yield actions.map(put)
    } catch (requestError) {
        const payload: RestCallFailed = {
            url,
            method: HttpMethods.GET,
            requestError,
        }
        put({
            type: ActionTypes.ADD_REST_CALL_FAILED_MESSAGE,
            payload,
        })
    }
}

export const watchers = [
    takeLatest(ActionTypes.ADD_REST_CALL_FAILED_MESSAGE, addRestCallFailedMessage),
    takeLatest(ActionTypes.FETCH_MESSAGE_FROM_SERVER, fetchGlobalMessageFromServer),
    takeLatest(ActionTypes.FETCH_ALL_MESSAGES_FROM_SERVER, fetchAllGlobalMessageFromServer),
]
