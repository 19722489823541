import React from 'react';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, mapGetters, setupMutations, superConnect, getModuleState } from 'core/util/module-utils';
import { getters as appModGetters } from 'APP';
import logger from 'score/logSvc';
import browserSvc from 'score/browserSvc';
import net from 'score/networkSvc';
import { isPuppeteer } from 'core/util/extractStyles';
import loadWithSSRFallback from 'ic/ui-elem/Loader';
import * as cls from 'eco/ContentSlot/ContentSlot.styles.module';
import USPWrapper from 'deco/Cms/USPWrapper.jsx';
import USPItem from 'deco/Cms/USPItem.jsx';
import { getProduct } from 'seco/productsSvc/product-factories';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const MultiColumnTextAndImage = loadWithSSRFallback(
  () => import('deco/Cms/MultiColumnTextAndImage.jsx'),
  'MultiColumnTextAndImage'
);
const Paragraph = loadWithSSRFallback(() => import('deco/Cms/Paragraph.jsx'), 'Paragraph');

const Link = loadWithSSRFallback(() => import('ic/ui-elem/Link.jsx'), 'Link');
const ProductPromotion = loadWithSSRFallback(
  () => import('@spa-ec/components/ProductPromotion/ProductPromotion'),
  'ProductPromotion'
);
const Carousel = loadWithSSRFallback(() => import('@ui-elem/Carousel/Carousel'), 'Carousel');
const TitleTextAndDynamicImageWrapper = loadWithSSRFallback(
  () => import('deco/Cms/TitleTextAndDynamicImageWrapper.jsx'),
  'TitleTextAndDynamicImageWrapper'
);
const Accordion = loadWithSSRFallback(() => import('ic/ui-elem/AccordionMod.jsx'), 'Accordion', {
  hydrate: 'inView',

  // hydrate: 'hover',

  // hydrate: 'never',

  // hydrate: 'always',

  // hydrate: 'delay',
  // timeout: 10000,
});

const TreeView = loadWithSSRFallback(() => import('ui/TreeViewMod'), 'TreeView');
const StylesBannerWrapper = loadWithSSRFallback(() => import('deco/Cms/StylesBannerWrapper.jsx'), 'StylesBannerWrapper');
const BannerWrapper = loadWithSSRFallback(() => import('deco/Cms/BannerWrapper.jsx'), 'BannerWrapper', {
  // hydrate: 'never',
  // hydrate: 'hover',
  // hydrate: 'never',
  // hydrate: 'always',
  // hydrate: 'never',
  timeout: 2000,
});
const ComponentsContainer = loadWithSSRFallback(() => import('deco/Cms/ComponentsContainer'), 'ComponentsContainer');
const FindModel = loadWithSSRFallback(() => import('@spa-ec/components/FindModel/FindModel'), 'FindModel');
const LogoList = loadWithSSRFallback(() => import('deco/Cms/LogoList.jsx'), 'LogoList');
const FlowImageAndText = loadWithSSRFallback(() => import('deco/Cms/FlowImageAndText.jsx'), 'FlowImageAndText');
const CategoryRecommendation = loadWithSSRFallback(() => import('deco/Cms/CategoryRecommendation.jsx'), 'CategoryRecommendation');
const ProductRecommendation = loadWithSSRFallback(
  () => import('eco/ProductRecommendation/ProductRecommendationMod.jsx'),
  'ProductRecommendationMod',
  {
    hydrate: 'hover',
  }
);
const Banner = loadWithSSRFallback(() => import('deco/Cms/Banner.jsx'), 'Banner');
const VideoCMS = loadWithSSRFallback(() => import('deco/Cms/VideoCMS.jsx'), 'VideoCMS');
const Video = loadWithSSRFallback(() => import('deco/Cms/Video.jsx'), 'Video');
const RoundImgAndText = loadWithSSRFallback(() => import('deco/Cms/RoundImgAndText.jsx'), 'RoundImgAndText');
const FixedImageAndText = loadWithSSRFallback(() => import('deco/Cms/FixedImageAndText.jsx'), 'FixedImageAndText');
const TextAndButton = loadWithSSRFallback(() => import('deco/Cms/TextAndButton.jsx'), 'TextAndButton');
const TitleTextAndDynamicImage = loadWithSSRFallback(
  () => import('deco/Cms/TitleTextAndDynamicImage.jsx'),
  'TitleTextAndDynamicImage'
);
const LinkedImage = loadWithSSRFallback(() => import('deco/Cms/LinkedImage.jsx'), 'LinkedImage');
const AccordionItem = loadWithSSRFallback(() => import('ic/ui-elem/AccordionItem.jsx'), 'AccordionItem');
const RightNowIFrame = loadWithSSRFallback(() => import('deco/Cms/RightNowIFrame.jsx'), 'RightNowIFrame');
const ProductUpsellCarousel = loadWithSSRFallback(
  () => import('eco/ProductUpsell/ProductUpsellCarouselMod.jsx'),
  'ProductUpsellCarouselMod',
  {
    hydrate: 'inView',
  }
);
const CSAdminBanner = loadWithSSRFallback(() => import('eco/CSAdminBanner/CSAdminBannerMod'), 'CSAdminBannerMod');
// const PersonalizedCockpit = loadWithSSRFallback(
//   () => import('eco/PersonalizedCockpit/PersonalizedCockpitMod'),
//   'PersonalizedCockpitMod',
//   {
//     hydrate: 'always',
//     force: true, // required as the personalized cockpit is not rendered by scod and mobile lazy loading dependons on if a component
//     // is visible this means that without force the component will not be rendered
//   }
// );
const InstagramFeed = loadWithSSRFallback(() => import('eco/InstagramFeed/InstagramFeedMod.jsx'), 'InstagramFeedMod');
const BrandsList = loadWithSSRFallback(() => import('deco/Cms/BrandsList.jsx'), 'BrandsList');

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'ContentSlotV2';

// Mudules data, this is the initial data for an instance
const initialState = {
  htmlContent: '',
  billboardContent: [],
  fetching: false,
  lastUpdate: 0,
  components: [],
  promotionProductsList: [],
};

const log = logger.getLogger(name); // eslint-disable-line
const conf = { multipleInstances, name, initialState };

const populateComponentLinkedProducts = (item, _disp) => {
  if (item.contentSlotItems && item.contentSlotItems.length > 0) {
    const products = [];
    item.contentSlotItems.forEach((fc) => {
      const props = fc.properties;
      if (!props) {
        return;
      }
      switch (fc.componentType) {
        case 'ProductPromotion':
          if (props.productData && props.productData.length > 0) {
            props.productData.map(getProduct).forEach((e) => products.push(e));
          }
          break;
        case 'ProductRecommendation':
        case 'ProductUpsellCarousel':
          if (props && props.content) {
            const pContent = JSON.parse(props.content);
            if (pContent.length && pContent.length > 0 && typeof pContent[0] === 'object') {
              pContent.map(getProduct).forEach((e) => products.push(e));
            }
          }
          break;
        default:
          break;
      }
    });
    if (products && products.length > 0) {
      _disp({ type: 'PROD_PRODUCTS', products, noAnalytics: true, source: 'populateComponentLinkedProducts' });
    }
  }
};

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  mapGetters(appModGetters(state, ownProps), instance, 'appMod', ['advnceEditFlag']);
  return instance;
};

const fetchDb = {};
let slotDB = [];
const specialSlotDB = {};
// ################# ACTIONS  #################
const actions = (dispatch, ownProps) => ({
  fetchV2All: () => {
    if (!ownProps.position) {
      return;
    }
    slotDB.push(ownProps.position);
    if (fetchDb[ownProps.pageId] === 'fetching') {
      return;
    }
    dispatch(async (_disp, getState) => {
      fetchDb[ownProps.pageId] = 'fetching';
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      let url = `${sessionConfig.urlPrefix}/rest/v2/page/contentSlots?pageId=${ownProps.pageId}`;
      // Added changes to check if the content page getting rendered is from scod
      // , if yes return the homepage content slots as the campaign pages can't be rendered properly
      // if the campaign is not activated
      if (isPuppeteer) {
        url += '&from_scod=true';
      }
      const res = await net.get(url);

      _disp({
        type: 'CONTENT_SLOT_BATCH',
        pageId: ownProps.pageId,
        iid: ownProps.iid,
        noAnalytics: true,
        contentSlotsList: res.contentSlots,
        applyToRoot: true,
      });

      const state = getModuleState(getState(), name, {
        name: conf.name,
        multipleInstances: false,
        initialState: conf.initialState,
      });
      if (state.promotionProductsList && state.promotionProductsList.length > 0) {
        _disp({ type: 'PROD_PRODUCTS', products: state.promotionProductsList, noAnalytics: true, source: 'fetchV2All' });
      }
      fetchDb[ownProps.pageId] = '';
    });
  },
  fetch: (useLegacyApi) => {
    if (!ownProps.position) {
      return;
    }
    if (specialSlotDB[ownProps.pageId]) {
      specialSlotDB[ownProps.pageId].push(ownProps.position);
    } else {
      specialSlotDB[ownProps.pageId] = [ownProps.position];
    }
    dispatch((_disp) => {
      let urlApi = '/rest/v2/contentSlot';
      if (useLegacyApi) {
        urlApi = '/contentSlot/';
      }
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      let url = `${sessionConfig.urlPrefix}${urlApi}?position=${ownProps.position}&pageId=${ownProps.pageId}`;
      if (ownProps.product) {
        url = url + '&product=' + ownProps.product;
      }
      if (ownProps.category) {
        url = url + '&category=' + ownProps.category;
      }
      if (ownProps.shelfCategory) {
        url = url + '&shelfCategory=' + ownProps.shelfCategory;
      }
      if (ownProps.brand && ownProps.typeCode) {
        url = url + '&brand=' + ownProps.brand + '&typeCategory=' + ownProps.typeCode;
      } else if (ownProps.typeCode) {
        url = url + '&typeCategory=' + ownProps.typeCode;
      }

      if (!fetchDb[ownProps.iid + '-' + ownProps.pageId]) {
        fetchDb[ownProps.iid + '-' + ownProps.pageId] = true;
        net.get(url).then(
          (result) => {
            _disp({
              type: 'CONTENT_SLOT',
              htmlContent: result,
              position: ownProps.position,
              pageId: ownProps.pageId,
              iid: ownProps.iid,
              autoPlay: true,
              noAnalytics: true,
            });
            populateComponentLinkedProducts(result, _disp);
            fetchDb[ownProps.iid + '-' + ownProps.pageId] = false;
          },
          () => {
            fetchDb[ownProps.iid + '-' + ownProps.pageId] = false;
          }
        );
      } else {
        log.trace('Already Fetching content data content data: ', url);
      }
    });
  },
});

// ################# MUTATIONS  #################

const componentFromObject = (item) => {
  const newComponent = {
    componentType: item.componentType,
    container: item.container,
    items: cloneDeep(item.items),
    properties: cloneDeep(item.properties),
  };
  return newComponent;
};

/**
 * Factory function to create a new content slot item
 * @param {String} componentType
 * @param {*} container
 * @param {*} items
 * @param {*} properties
 * @returns
 */
const componentFromAttr = (componentType, container, items, properties) => {
  const newComponent = {
    componentType,
    container,
    properties: cloneDeep(properties),
  };
  if (items && items.length > 0) {
    newComponent.items = cloneDeep(items);
  }
  return newComponent;
};
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  CONTENT_SLOT_FETCHING: (state, action) => {
    state.fetching = action.fetching;
  },
  CONTENT_SLOT: (state, action) => {
    state.fetching = false;
    state.lastUpdate = new Date().getTime();
    const components = [];
    const contentSlotItems = action.htmlContent.contentSlotItems;
    const cmsLegacyHtml = action.htmlContent.cmsSlotHtml;
    const componentProperties = action.htmlContent.properties;
    if (contentSlotItems) {
      contentSlotItems.forEach((component) => {
        components.push(componentFromObject(component));
      });
      // Adding a temporary fix for the qliro components which are not getting rendered at the legacy sites.
    } else if (componentProperties !== undefined) {
      components.push(componentFromObject(action.htmlContent));
    } else if (cmsLegacyHtml && (action.pageId === 'singleStepQliroCheckoutSummaryPage' || action.pageId === 'qliro_down')) {
      components.push(componentFromAttr('Paragraph', 'false', [], { content: cmsLegacyHtml }));
    }
    state.components = components;
    state.contentSlotId = action.htmlContent.contentSlotId;
    state.contentSlotUuid = action.htmlContent.contentSlotUuid;
    state.contentSlotCatalogVersion = action.htmlContent.contentSlotCatalogVersion;
  },
  CONTENT_SLOT_BATCH: (state, action) => {
    action.contentSlotsList.forEach((item) => {
      slotDB = slotDB.filter((s) => s !== item.position);
      if (!(specialSlotDB[action.pageId] && specialSlotDB[action.pageId].includes(item.position))) {
        state.fetching = false;
        state.lastUpdate = new Date().getTime();
        const components = [];
        const contentSlotItems = item.contentSlotItems;
        const cmsLegacyHtml = item.cmsSlotHtml;
        const componentProperties = item.properties;
        if (contentSlotItems) {
          contentSlotItems.forEach((component) => {
            components.push(componentFromObject(component));
            const products = [];
            const props = component.properties;
            switch (component.componentType) {
              case 'ProductPromotion':
                if (props.productData && props.productData.length > 0) {
                  props.productData.map(getProduct).forEach((e) => products.push(e));
                }
                break;
              case 'ProductRecommendation':
              case 'ProductUpsellCarousel':
                if (props && props.content) {
                  const pContent = JSON.parse(props.content);
                  if (pContent.length && pContent.length > 0 && typeof pContent[0] === 'object') {
                    pContent.map(getProduct).forEach((e) => products.push(e));
                  }
                }
                break;
              default:
                break;
            }
            if (products && products.length > 0) {
              state.promotionProductsList = products;
            }
          });
          // Adding a temporary fix for the qliro components which are not getting rendered at the legacy sites.
        } else if (componentProperties !== undefined) {
          components.push(componentFromObject(item));
        } else if (cmsLegacyHtml && action.pageId === 'singleStepQliroCheckoutSummaryPage') {
          components.push(componentFromAttr('Paragraph', 'false', [], { content: cmsLegacyHtml }));
        }
        state[action.pageId + '---' + item.position] = {};
        state[action.pageId + '---' + item.position].contentSlotUuid = item.contentSlotUuid;
        state[action.pageId + '---' + item.position].contentSlotId = item.contentSlotId;
        state[action.pageId + '---' + item.position].contentSlotCatalogVersion = item.contentSlotCatalogVersion;
        if (components.length > 0) {
          state[action.pageId + '---' + item.position].components = components;
        }
      }
    });
  },
  LEGACY_CAMPAIGN_ACTIVATED(state, _action) {
    this.useRootState = true;
    const keys = Object.keys(state);
    keys.forEach((k) => {
      if (typeof state[k] === 'object') {
        state[k].lastUpdate = new Date(0).getTime(); // eslint-disable-line
      }
    });
  },
};
mutations.LEGACY_CAMPAIGN_ACTIVATED.useRootState = true;

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = { name, conf, state: setupInitialState(initialState, conf), getters, actions, mutations: setupMutations(mutations, conf) }; // eslint-disable-line

export const _renderComp = (item, contentSlotProps) => {
  if (item !== '') {
    return renderComponent(item.container, item.componentType, item.items, item.properties, contentSlotProps);
  }
  return null;
};

const renderComponent = (container, componentType, _items, _properties, contentSlotProps) => {
  const properties = _properties || {};
  const items = _items || [];
  const smarteditCatalogVersion = browserSvc.localStorageGet('smarteditCatalogVersion');

  if (componentType) {
    if (container !== 'false') {
      switch (componentType) {
        case 'Fragment':
          return <>{items.map((item) => renderComponent(item.container, item.componentType, item.items, item.properties))}</>;
        case 'StylesBannerWrapper': {
          return (
            <StylesBannerWrapper
              key={properties.uid}
              SSRKey={properties.uid}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.map((item) => renderComponent(item.container, item.componentType, item.items, item.properties))}
            </StylesBannerWrapper>
          );
        }
        case 'Carousel': {
          return (
            <Carousel
              key={properties.uid}
              SSRKey={properties.uid}
              autoPlay={properties.autoPlay === 'true' || properties.autoPlay}
              autoPlaySpeed={properties.autoPlaySpeed}
              wrapperTheme={properties.wrapperTheme}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.map((item) => renderComponent(item.container, item.componentType, item.items, item.properties))}
            </Carousel>
          );
        }
        case 'USPWrapper': {
          return (
            <USPWrapper
              key={properties.uid}
              SSRKey={properties.uid}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.map((item) => _renderComp(item, contentSlotProps))}
            </USPWrapper>
          );
        }
        case 'TitleTextAndDynamicImageWrapper': {
          return (
            <TitleTextAndDynamicImageWrapper
              key={properties.uid}
              SSRKey={properties.uid}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.forEach((item) => {
                if (item !== '') {
                  renderComponent(item.container, item.componentType, item.items, item.properties);
                }
              })}
            </TitleTextAndDynamicImageWrapper>
          );
        }
        case 'Accordion': {
          return (
            <Accordion
              key={properties.uid}
              SSRKey={properties.uid}
              items={items}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.map((item) => _renderComp(item, contentSlotProps))}
            </Accordion>
          );
        }
        case 'BannerWrapper': {
          return (
            <BannerWrapper
              key={properties.uid}
              SSRKey={properties.uid + 'banner-wrapper'}
              elementUid={properties.elementUid}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
              componentParentType={properties.parentType}
            >
              {items.map((item) => _renderComp(item, contentSlotProps))}
            </BannerWrapper>
          );
        }
        case 'LogoList': {
          return <LogoList key={properties.uid} SSRKey={properties.uid} items={items} properties={properties} />;
        }
        case 'ComponentsContainer': {
          return (
            <ComponentsContainer
              wrapperClasses={'w-full'}
              iid={properties.uid}
              key={properties.uid}
              SSRKey={properties.uid}
              items={items}
              properties={properties}
              advanceEditFlag={contentSlotProps.appMod.advnceEditFlag}
              contentSlotCatalogVersion={smarteditCatalogVersion || null}
            />
          );
        }
        case 'TreeView': {
          return <TreeView key={properties.uid} SSRKey={properties.uid} items={items} properties={properties} />;
        }
        default:
          return <div>Component with children not implemented: {componentType}</div>;
      }
    }
    switch (componentType) {
      case 'MultiColumnTextAndImage': {
        return (
          <MultiColumnTextAndImage
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'FlowImageAndText': {
        return (
          <FlowImageAndText
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'LinkedImage': {
        return (
          <LinkedImage
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'Banner': {
        return (
          <Banner
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'VideoCMSComponent': {
        return (
          <VideoCMS
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'VideoComponent': {
        return (
          <Video
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'USPItem':
        return (
          <USPItem
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      case 'RoundImgAndText': {
        return (
          <RoundImgAndText
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'FixedImageAndText': {
        return (
          <FixedImageAndText
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'TextAndButton': {
        return (
          <TextAndButton
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'Paragraph': {
        return (
          <Paragraph
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'TitleTextAndDynamicImage': {
        return (
          <TitleTextAndDynamicImage
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'AccordionItem': {
        return (
          <AccordionItem
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
            cleanText={true}
          >
            {properties.content}
          </AccordionItem>
        );
      }
      case 'RightNowIFrame': {
        return (
          <RightNowIFrame
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'ProductUpsellCarousel': {
        return (
          <ProductUpsellCarousel
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'FindModelMod': {
        const smartEdit = {
          SSRKey: properties.uid,
          elementUid: properties.elementUid,
          contentSlotCatalogVersion: smarteditCatalogVersion || null,
          componentParentType: properties.parentType,
        };
        return (
          <FindModel
            componentType={componentType}
            key={properties.uid}
            smartEdit={smartEdit}
            mainTitle={properties.mainTitle}
            selectBrandTitle={properties.selectBrandTitle}
            brands={properties.brands}
            position={contentSlotProps.position}
            showAllLink={properties.showAllLink}
          />
        );
      }
      // case 'PersonalizedCockpit': {
      // return (
      // <PersonalizedCockpit
      //   componentType={componentType}
      //   elementUid={properties.elementUid}
      //   componentParentType={properties.parentType}
      //   contentSlotCatalogVersion={smarteditCatalogVersion || null}
      //   key={properties.uid}
      //   SSRKey={properties.uid}
      //   {...properties}
      // />
      // );
      // }
      case 'Link': {
        return (
          <Link
            {...properties}
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      case 'CSAdminBanner': {
        return (
          <CSAdminBanner
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={'CAB' + properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      case 'ProductRecommendation': {
        return (
          <ProductRecommendation
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            {...properties}
            key={properties.uid}
            iid={properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      case 'InstagramFeed': {
        return (
          <InstagramFeed
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            key={properties.uid}
            SSRKey={properties.uid}
            {...properties}
          />
        );
      }
      case 'CategoryRecommendation': {
        return (
          <CategoryRecommendation
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            {...properties}
            key={properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      case 'BrandsList': {
        return (
          <BrandsList
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            {...properties}
            key={properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      case 'ProductPromotion': {
        return (
          <ProductPromotion
            componentType={componentType}
            elementUid={properties.elementUid}
            componentParentType={properties.parentType}
            contentSlotCatalogVersion={smarteditCatalogVersion || null}
            {...properties}
            key={properties.uid}
            SSRKey={properties.uid}
          />
        );
      }
      default:
        return (
          <div>
            <h3>{componentType}</h3>TBD
          </div>
        );
    }
  }
};

let _DebugMode = false;
if (typeof location !== 'undefined') {
  _DebugMode = browserSvc.sessionGet('cms-debug-mode') === 'true';
}

// ################# RENDER  #################
class ContentSlotV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidMount() {
    const p = this.props;
    log.debug(
      'Position: ',
      p.position,
      ' Cache diff ',
      new Date().getTime() - Math.abs(p.lastUpdate),
      'remove cache :' + p.removeCaching
    );
    if (new Date().getTime() - Math.abs(p.lastUpdate) > 60 * 1000 * 30 || logger.cacheDisabled() || p.removeCaching) {
      // Note that the fetch method will bail out if another fetch for this slot is in progress
      // This state will reset by a page reload
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      if (
        sessionConfig.useCMSAPIForThinState ||
        p.product ||
        p.category ||
        p.shelfCategory ||
        p.brand ||
        p.typeCode ||
        p.removeCaching ||
        p.iid !== p.pageId + '---' + p.position
      ) {
        p.fetch(p.useLegacyApi);
      } else {
        p.fetchV2All();
      }
    } else {
      log.trace('Skipping fetching...', p.position, p.iid);
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(_error, _info) {
    this.setState({ hasError: true });
  }

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const p = this.props;
    let isAdvEditActive = this.props.appMod.advnceEditFlag;
    if (this.state.hasError) {
      return null;
    }

    return _DebugMode ? (
      <div className={cls.slotDebug + ' mb-1'}>
        <div className={'px-4 py-1 ' + cls.slotInfo}>
          {p.position} -- {p.pageId}
        </div>
        {p.components && p.components.length > 0
          ? p.components.map((component) => {
              return (
                <div>
                  <div className={'px-4 py-1 ' + cls.slotInfo}>
                    Type: {component.componentType} Uid: {component.properties.uid || 'No uid'}
                  </div>
                  {renderComponent(component.container, component.componentType, component.items, component.properties, p)}
                </div>
              );
            })
          : null}
      </div>
    ) : (
      <>
        {(p.components && p.components.length > 0) || isAdvEditActive ? (
          <div
            className="smartEditComponent"
            data-smartedit-component-id={p.contentSlotId}
            data-smartedit-component-uuid={p.contentSlotUuid}
            data-smartedit-catalog-version-uuid={p.contentSlotCatalogVersion}
            data-smartedit-component-type={'ContentSlot'}
          >
            {p.components && p.components.length > 0
              ? p.components.map((component) => {
                  return renderComponent(component.container, component.componentType, component.items, component.properties, p);
                })
              : null}
          </div>
        ) : null}
      </>
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, ContentSlotV2);
