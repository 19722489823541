import { getProductStockStatus, parseImageSubset } from '../products/utils'
import { CartEntry } from './interfaces'

export const parseCartEntries = (entries: CartEntry[], fallbackImage: string): CartEntry[] => {
    entries.forEach((entry: CartEntry) => {
        entry.product.imagesSubset = parseImageSubset(entry.product.imagesSubset, fallbackImage)
        entry.stockStatus = getProductStockStatus(entry?.entryStockStatus || entry?.product?.stockStatus)
    })
    return entries
}
