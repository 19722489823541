export const NAME = 'app'

export const ActionTypes = {
    FETCH_PROMOTIONS: `${NAME}/FETCH_PROMOTIONS`,
    FETCHED_PROMOTIONS: `${NAME}/FETCHED_PROMOTIONS`,
    UPDATE_SESSION_CONFIG: `${NAME}/UPDATE_SESSION_CONFIG`,
    SET_UPDATING_SESSION_CONFIG: `${NAME}/SET_UPDATING_SESSION_CONFIG`,
    SET_CONFIG: `${NAME}/SET_CONFIG`,
    PRODUCT_DETAILS_VIEWED: `${NAME}/PRODUCT_DETAILS_VIEWED`,
    PRODUCT_INFORMATION_EXPANDED: `${NAME}/PRODUCT_INFORMATION_EXPANDED`,
    PRODUCT_SPECIFICATION_EXPANDED: `${NAME}/PRODUCT_SPECIFICATION_EXPANDED`,
    SET_PURCHASE_QUANTITY: `${NAME}/SET_PURCHASE_QUANTITY`,
    INIT_APPLICATION: `${NAME}/INIT_APPLICATION`,
    FETCH_INIT_CONFIG: `${NAME}/FETCH_INIT_CONFIG`,
    SET_CUSTOMER_TYPE_CHANGE_WARNING: `${NAME}/SET_CUSTOMER_TYPE_CHANGE_WARNING`,
    CHECK_CUSTOMER_TO_BE_LOGGED_OFF: `${NAME}/CHECK_CUSTOMER_TO_BE_LOGGED_OFF`,
    LOG_OUT_SWITCH_CUSTOMER_TYPE_OR_CHANGE_LANGUAGE: `${NAME}/LOG_OUT_CUSTOMER_AND_SWITCH_CUSTOMER_TYPE`,
    SET_COLLAPSABLE_PANEL_EXPANDED: `${NAME}/SET_COLLAPSABLE_PANEL_EXPANDED`,
}

export enum ExternalScriptContentType {
    HTML = 'HTML',
    SCRIPT = 'SCRIPT',
    URLS_AFTER_ORDER = 'URLS_AFTER_ORDER',
}

export enum ExternalScriptEvent {
    ALL_PAGES = 'ALL_PAGES',
    PAGE_VIEW = 'PAGE_VIEW',
    FETCH_ORDER_CONF_DATA = 'FETCH_ORDER_CONF_DATA',
    SPA_PLACING_ORDER = 'SPA_PLACING_ORDER',
    CART_SVC_DATA = 'CART_SVC_DATA',
    CART_SVC_ADD = 'CART_SVC_ADD',
}

export enum CustomerType {
    PRIVATE = 'private',
    COMPANY = 'company',
}
