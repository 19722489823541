const themeGen = theme => { // eslint-disable-line
  const res = {
    conf: {
      showOneKronaSticker: true,
      environmentalBkg: '#cdefcf',
    },
  };
  return res;
};

export default themeGen;
