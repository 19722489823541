import logger from '@spa-core-js/services/logSvc'
import browserSvc from '@spa-core-js/services/browserSvc'
import setupLogRocketReact from 'logrocket-react'
import { handleScript } from '@spa-ec/util/dangerouslyAddScript'
import { isPuppeteer } from '@spa-core-js/util/extractStyles'
import { ExternalScriptEvent, NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { ExternalScript, ExternalScripts, SessionConfig } from '@spa-core/store/app/interfaces'
import { TrackingActionTypes } from '../tracking/constants'

const LOG_ROCKET_ID_KEY: string = 'LOG_ROCKET_ID'
const LOG_ROCKET_KEY: string = 'LogRocket'

interface QueuedExternalScript {
    legacyEvent: ExternalScriptEvent
    scriptHydrationData: any
}

const log = logger.getLogger('third-parties-middleware')
const externalScriptsMap: ExternalScripts = {}
const externalScriptsQueue: QueuedExternalScript[] = []
let scriptsAreLoaded: boolean = false

const startLogRocket = (sessionConfig): void => {
    if (window[LOG_ROCKET_ID_KEY] && !isPuppeteer && !sessionConfig.internalSession) {
        try {
            window[LOG_ROCKET_KEY].init(window[LOG_ROCKET_ID_KEY])
            setupLogRocketReact(window[LOG_ROCKET_KEY])

            // This is an example script - don't forget to change it!
            setTimeout(() => {
                if (browserSvc.cookieGet('nickname')) {
                    window[LOG_ROCKET_KEY].identify(browserSvc.cookieGet('ID2'), {
                        name: browserSvc.cookieGet('nickname'),
                        ID2: browserSvc.cookieGet('ID2'),
                        JSESSIONID: browserSvc.cookieGet('JSESSIONID'),
                    })
                } else if (browserSvc.getUrlParam('nickname')) {
                    const nickname = browserSvc.getUrlParam('nickname')
                    browserSvc.cookieSet('nickname', nickname)
                    window[LOG_ROCKET_KEY].identify(browserSvc.cookieGet('ID2'), {
                        name: nickname,
                        ID2: browserSvc.cookieGet('ID2'),
                        JSESSIONID: browserSvc.cookieGet('JSESSIONID'),
                    })
                } else {
                    window[LOG_ROCKET_KEY].identify(browserSvc.cookieGet('ID2'), {
                        name: browserSvc.cookieGet('JSESSIONID'),
                        ID2: browserSvc.cookieGet('ID2'),
                        JSESSIONID: browserSvc.cookieGet('JSESSIONID'),
                    })
                }
            }, 1000)
        } catch (e) {
            log.warn('LogRocket initialization failed')
        }
    }
}

const generateScriptId = (): string => '_' + Math.random().toString(36).substring(2, 9)

const legacyEventNameMap = {
    [TrackingActionTypes.PAGE_VIEW]: ExternalScriptEvent.PAGE_VIEW,
    [TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA]: ExternalScriptEvent.FETCH_ORDER_CONF_DATA,
    [TrackingActionTypes.START_PLACING_QLIRO_ORDER]: ExternalScriptEvent.SPA_PLACING_ORDER,
    [TrackingActionTypes.FETCHED_CART]: ExternalScriptEvent.CART_SVC_DATA,
    [TrackingActionTypes.CART_UPDATED]: ExternalScriptEvent.CART_SVC_ADD,
}

export const handleInitScripts = (scripts: ExternalScripts, sessionConfig: SessionConfig) => {
    if (!sessionConfig.isSPA) {
        return
    }

    Object.keys(scripts).forEach((executionContext: ExternalScriptEvent) => {
        const sortedScripts: ExternalScript[] = scripts[executionContext].sort((a, b) => b.priority - a.priority)
        externalScriptsMap[executionContext] = sortedScripts.map((initScript: ExternalScript) => ({
            ...initScript,
            id: generateScriptId(),
        }))
    })

    externalScriptsMap[ExternalScriptEvent.ALL_PAGES].forEach((initScript: ExternalScript) => {
        try {
            handleScript(initScript, {}, sessionConfig)
        } catch (err) {
            log.error('Fail init for third party scripts:', err)
        }
    })

    let count: number = 0
    const handler: NodeJS.Timeout = setInterval(() => {
        count++
        if (!window[LOG_ROCKET_ID_KEY] && count > 10) {
            clearInterval(handler)
        }
        if (count > 1000) {
            clearInterval(handler)
            log.debug('LogRocket could not be started') // eslint-diable-line
        }
        if (window[LOG_ROCKET_KEY]?.init) {
            startLogRocket(sessionConfig)
            clearInterval(handler)
        }
    }, 10)

    setTimeout(() => {
        scriptsAreLoaded = true
        /**
         * If events present in legacyEventNameMap has been fired before the init scripts are loaded
         * they are put in queue and executed here.
         */
        externalScriptsQueue.forEach(({ legacyEvent, scriptHydrationData }: QueuedExternalScript) => {
            externalScriptsMap[legacyEvent]?.forEach((externalScript: ExternalScript) => {
                try {
                    handleScript(externalScript, scriptHydrationData, sessionConfig)
                } catch (error) {
                    log.error('Fail executing third party script after init loaded:', { legacyEvent, error })
                }
            })
        })
        externalScriptsQueue.length = 0
    }, 10)
}

export const clearEvents = () => {
    Object.keys(externalScriptsMap).forEach((event: ExternalScriptEvent) => {
        delete externalScriptsMap[event]
    })
}

const thirdParties = (store) => (next) => (action) => {
    if (!store) {
        return
    }
    const sessionConfig: SessionConfig = store?.reducers?.[sessionReducerName]?.sessionConfig || window['sessionConf']
    if (!sessionConfig.isSPA) {
        next(action)
        return
    }

    if (action) {
        const { type, payload } = action
        const legacyEvent: ExternalScriptEvent = legacyEventNameMap[action.type]
        if (legacyEvent && scriptsAreLoaded) {
            externalScriptsMap[legacyEvent]?.forEach((externalScript: ExternalScript) => {
                try {
                    handleScript(externalScript, payload, sessionConfig)
                } catch (error) {
                    log.error('Fail executing third party script:', { legacyEvent, error })
                }
            })
        } else if (legacyEvent && !scriptsAreLoaded) {
            externalScriptsQueue.push({
                legacyEvent,
                scriptHydrationData: payload,
            })
        }
        switch (type) {
            case TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA: {
                const { affiliateReportingScript, affiliateReportEvent, affiliateReportData } = payload?.data
                const orderConfirmationScript: ExternalScript = affiliateReportEvent || affiliateReportingScript
                if (orderConfirmationScript) {
                    handleScript(orderConfirmationScript, affiliateReportData || {}, sessionConfig)
                }
                break
            }
            default:
        }
        next(action)
    }
}

export default thirdParties
