import logger from 'score/logSvc';
import IC from 'IC';

const log = logger.getLogger('UrlSvc'); // eslint-disable-line

/**
 * Service class for handling {illuminating text here}.
 */
class UrlSvc {
  getBaseUrl() {
    const urlService = new IC.Url();
    const baseUrl = urlService.get().baseUrl;
    return baseUrl;
  }

  checkIfUrlIsAbsolute(url) {
    const urlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
    return urlRegex.test(url);
  }
}

const service = new UrlSvc();

export default service;
