import browserSvc from 'score/browserSvc';
import net from 'score/networkSvc';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const logUrl = (id, val, id2, val2) => {
  const ID2 = browserSvc.cookieGet('ID2');
  const JSESSIONID = browserSvc.cookieGet('JSESSIONID');
  const sessionConfig = getReducer(sessionReducerName).sessionConfig;
  // Do not change to another gif like log.gif, this makes this request vulnerable to the adblockers
  const url = `${
    sessionConfig.contextPath
  }/_ui/dist/images/lightbox-blank.gif?${id}=${val}&JSESSIONID=${JSESSIONID}&ID2=${ID2}&siteId=${sessionConfig.siteName}${
    id2 ? '&' + id2 + '=' + val2 : ''
  }`;

  net.get(url);
};

if (typeof window !== 'undefined') {
  window.logUrl = logUrl;
} else {
  global.logUrl = logUrl;
}

export default logUrl;
