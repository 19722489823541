import { ActionTypes } from './constants'
import { CategorySelectedFacets, CategoryUserLimits, FetchCategoryPayload } from './interfaces'

export const fetchCategory = (payload: FetchCategoryPayload) => ({
    type: ActionTypes.FETCH_CATEGORY,
    payload,
})

export const setFetchingFilterCode = (filterCode: string) => ({
    type: ActionTypes.SET_FETCHING_FILTER_CODE,
    payload: {
        filterCode,
    },
})

export const fetchFilters = (categoryCode: string) => ({
    type: ActionTypes.FETCH_FILTERS,
    payload: {
        categoryCode,
    },
})

export const setSelectedFacets = (categoryCode: string, selectedFacets: CategorySelectedFacets) => ({
    type: ActionTypes.SET_SELECTED_FACETS,
    payload: {
        categoryCode,
        selectedFacets,
    },
})

export const setLoading = (loading: boolean) => ({
    type: ActionTypes.SET_LOADING,
    payload: {
        loading,
    },
})

export const setAppendingProducts = (appendingProducts: boolean) => ({
    type: ActionTypes.SET_APPENDING_PRODUCTS,
    payload: {
        appendingProducts,
    },
})

export const toggleExpandedNavigationTree = (treeIndex: string, categorySectionId: string) => ({
    type: ActionTypes.TOGGLE_EXPANDED_NAVIGATIONTREE,
    payload: {
        treeIndex,
        categorySectionId,
    },
})

export const setUserLimits = (categoryCode: string, userLimits: CategoryUserLimits) => ({
    type: ActionTypes.SET_USER_LIMITS,
    payload: {
        userLimits,
        categoryCode,
    },
})

export const setCategory = (sectionUid: string, categoryPath: number[], categoryCode: string) => ({
    type: ActionTypes.SET_CATEGORY,
    payload: {
        sectionUid,
        categoryPath,
        categoryCode,
    },
})

export const setSorting = (sorting: string) => ({
    type: ActionTypes.SET_SORTING,
    payload: {
        sorting,
    },
})

export const fetchSubcategories = (categoryCode: string) => ({
    type: ActionTypes.FETCH_SUBCATEGORIES,
    payload: {
        categoryCode,
    },
})
