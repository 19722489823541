import siteTheme from '@ic-site-theme';
import overrides from '@ic-site-theme-overrides';
import * as mixins from '@spa-core-js/mixins';
import { merge } from 'ts-deepmerge';
import defaultTheme from './default-theme';
const mergedTheme = merge.withOptions({ mergeArrays: false }, defaultTheme, siteTheme);
export default mergedTheme;
/**
 * @deprecated Use getThemeFactory instead
 */
export const getTheme = (componentName, componentProps = undefined) => {
    var _a;
    return merge.withOptions({ mergeArrays: false }, mergedTheme, componentProps || {}, ((_a = overrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(overrides, mergedTheme)) || {});
};
const themeFactoryGenerator = (componentName, componentProps, generatorOverrides) => {
    var _a;
    const componentOverride = ((_a = generatorOverrides === null || generatorOverrides === void 0 ? void 0 : generatorOverrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(generatorOverrides, mergedTheme)) || {};
    const generatedTheme = merge.withOptions({ mergeArrays: false }, siteTheme, componentProps || {}, componentOverride, mergedTheme);
    mixins.setTheme(generatedTheme);
    return () => generatedTheme;
};
export const getThemeFactory = (componentName, componentProps) => themeFactoryGenerator(componentName, componentProps, overrides);
