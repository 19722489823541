export enum TrackingActionTypes {
    PAGE_VIEW = 'track-page-view',
    CAMPAIGN_ACTIVATED = 'track-campaign-activated',
    PROMOTIONS_UPDATED = 'track-promotions-updated',
    FETCHED_CART = 'track-fetched-cart',
    CART_UPDATED = 'track-cart-updated',
    CART_PRODUCT_QUANTITY_INCREASE = 'track-cart-product-quantity-increase',
    CART_PRODUCT_QUANTITY_DECREASE = 'track-cart-product-quantity-decrease',
    ADD_PAYMENT_INFO = 'track-add-payment-info',
    ADD_SHIPPING_INFO = 'track-add-shipping-info',
    BEGIN_CHECKOUT = 'track-begin-checkout',
    VIEW_CART_PAGE = 'track-view-cart-page',
    FETCHED_CATEGORIES = 'track-fetched-categories',
    VIEWED_PRODUCT = 'track-viewed-product',
    REPLACE_PRODUCT_WITH_OWN_BRAND = 'track-replace-product-with-own-brand',
    REPLACE_ALL_PRODUCTS_WITH_OWN_BRAND = 'track-replace-all-products-with-own-brand',
    CANCEL_REPLACE_PRODUCT_WITH_OWN_BRAND = 'track-cancel-replace-all-product-with-own-brand',
    VALIDATED_CHECKOUT_INPUT_FIELD = 'track-validated-checkout-input-field',
    SEND_PLACE_ORDER = 'track-send-place-order',
    SEARCH_RESULTS = 'track-search-results',
    AUTOCOMPLETE_SEARCH_RESULTS = 'track-autocomplete-search-results',
    HEADER_SEARCH_RESULT_BOX_CLOSED = 'track-header-search-result-box-closed',
    SESSION_CONFIG_UPDATED = 'track-session-config-updated',
    SET_CATEGORY = 'track-set-category',
    OPEN_FILTER = 'track-open-filter',
    ACTIVATE_FILTER = 'track-activate-filter',
    FETCHED_ORDER_CONFIRMATION_DATA = 'track-fetched-order-confirmation-data',
    START_PLACING_QLIRO_ORDER = 'START_PLACING_QLIRO_ORDER',
}

export enum MatomoLevel {
    OFF = 0, // OFF
    IMPORTANT = 1, // ERROR
    MEDIUM = 2, // INFO
    ALL = 3, // ALL (DEBUG)
}

export enum GoogleAnalyticsCategory {
    CART = 'CART',
    OTHER = 'OTHER',
    SEARCH_HIT = 'SEARCH_HIT',
    SEARCH_RESULT = 'SEARCH_RESULT',
    SEARCH_FULL_PAGE = 'SEARCH_FULL_PAGE',
    SESSION = 'SESSION',
}

export enum GoogleAnalyticsType {
    IDENTIFIED = 'IDENTIFIED',
}

export const GTM_TRACKING_QUEUE_KEY: string = 'gtm-tracking-queue'
export const MAX_TRACKING_QUEUE_LENGTH: number = 10
