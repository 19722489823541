export const NAME = 'cart'

export const ActionTypes = {
    ADD_TO_CART: `${NAME}/ADD_TO_CART`,
    UPDATE_CART_ENTRY: `${NAME}/UPDATE_CART_ENTRY`,
    SET_CART_DATA_IN_STORE: `${NAME}/SET_CART_DATA_IN_STORE`,
    FETCH_CART: `${NAME}/FETCH_CART`,
    SET_UPDATING_CART: `${NAME}/SET_UPDATING_CART`,
    SET_CURRENTLY_ADDING_TO_CART: `${NAME}/SET_CURRENTLY_ADDING_TO_CART`,
    ITEM_ADDED: `${NAME}/ITEM_ADDED`,
    ADD_SUBSCRIPTION_PRODUCT_TO_CART: `${NAME}/ADD_SUBSCRIPTION_PRODUCT_TO_CART`,
    FETCH_RECOMMENDED_CART: `${NAME}/FETCH_RECOMMENDED`,
    FETCHED_RECOMMENDED_CART: `${NAME}/FETCHED_RECOMMENDED_CART`,
    HANDLE_ABANDONED_CART: `${NAME}/HANDLE_ABANDONED_CART`,
    RESTORE_ABANDONED_CART: `${NAME}/RESTORE_ABANDONED_CART`,
    REJECT_ABANDONED_CART: `${NAME}/REJECT_ABANDONED_CART`,
    SET_SHOW_RESTORE_CART: `${NAME}/SET_SHOW_RESTORE_CART`,
    SET_RESTORING_ABANDONED_CART: `${NAME}/SET_RESTORING_ABANDONED_CART`,
    SET_RESTORE_CART_TOTAL_ITEMS: `${NAME}/SET_RESTORE_CART_TOTAL_ITEMS`,
    FETCH_ORDER_CONFIRMATION: `${NAME}/FETCH_ORDER_CONFIRMATION`,
    FETCHED_ORDER_CONFIRMATION: `${NAME}/FETCHED_ORDER_CONFIRMATION`,
    RESET_SUBSCRIPTION_INTERVAL: `${NAME}/RESET_SUBSCRIPTION_INTERVAL`,
    SET_SUBSCRIPTION_INTERVAL: `${NAME}/SET_SUBSCRIPTION_INTERVAL`,
}

export enum PurchaseType {
    ONE_TIME = 'onetime',
    SUBSCRIPTION = 'subscription',
}
