import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addRestCallFailed } from '@spa-core/store/global-messages/actions'
import { HttpMethods } from '@spa-core/constants/HttpMethods'
import net from '@spa-core-js/services/networkSvc'
import { SpaError, ErrorTypes } from '@spa-ec/components/Error/Error'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { getReducer } from '@spa-core/legacy-adapter/utils'
import { SessionConfig } from '@spa-core/store/app/interfaces'

const CatchAllRoutes: React.FC = () => {
    const [errorType, setErrorType] = useState<ErrorTypes>()
    const [errorPageId, setErrorPageId] = useState<string>()
    const dispatch = useDispatch()
    useEffect(() => {
        fetchRouteInfo()
    })

    const fetchRouteInfo = async (): Promise<void> => {
        const pageId: string = location.pathname.split('/').pop()
        if (!pageId) {
            return
        }
        const sessionConfig: SessionConfig = getReducer(sessionReducerName).sessionConfig
        const getRouteInfoUrl: string = `${sessionConfig.urlPrefix}/rest/v1/campaign/expired/${pageId}`
        let result: any
        try {
            result = await net.get(getRouteInfoUrl)
        } catch (error) {
            dispatch(addRestCallFailed({ url: `/rest/v1/campaign/expired/${pageId}`, method: HttpMethods.GET, error }))
        }
        if (!result || (result?.redirectUrl && result.redirectUrl.indexOf('notFound') > 0)) {
            setErrorPageId('notFound')
            setErrorType(ErrorTypes.NOT_FOUND)
        }
        if (result?.redirectUrl && result?.redirectUrl.indexOf('inactivecampaign') > 0) {
            setErrorPageId('inactivecampaign')
            setErrorType(ErrorTypes.INACTIVE_CAMPAIGN_PAGE)
        }
    }

    if (errorType && errorPageId) {
        throw new SpaError({ errorType, pageId: errorPageId })
    }

    return null
}

export default CatchAllRoutes
