import logger from '@spa-core-js/services/logSvc'
import net from '@spa-core-js/services/networkSvc'
import { call, put, select, takeLatest, takeEvery, takeLeading } from 'redux-saga/effects'
import { selectSessionConfig } from '../utils'
import { ActionTypes, CustomerType } from './constants'
import {
    Promotions,
    InitRequestResult,
    SessionConfig,
    SetConfigPayload,
    AppStore,
    CheckCustomerResult,
    RedirectResponse,
    CheckCustomerToBeLoggedOffPayload,
} from './interfaces'
import { ActionTypes as ProductActionTypes } from '../products/constants'
import { ActionTypes as AppActionTypes } from '../app/constants'
import { addRestCallFailed, fetchMessageFromServer } from '../global-messages/actions'
import { HttpMethods } from '@spa-core/constants/HttpMethods'
import { isPuppeteer } from '@spa-core-js/util/extractStyles'
import { CartDataResponse, CartEntry, SetCartDataPayload } from '../cart/interfaces'
import { GoogleAnalyticsCategory, GoogleAnalyticsType, TrackingActionTypes } from '@spa-core/tracking/constants'
import { handleInitScripts } from '../../middleware/third-parties-middleware'
import { restoreAbandonedCart, setCartDataInStore } from '../cart/actions'
import { ActionTypes as CartActionTypes } from '../cart/constants'
import theme from '@ic-theme'
import { parseImageSubset } from '../products/utils'
import { getCampaignId, isCampaign } from '@spa-core-js/util/campaign'
import { activateCampaign } from '../campaign/sagas'
import { Store } from '../'

import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { navigateTo } from '../navigation/actions'

const WARNING_CART_ONEBUYONLY_HAS_REMOVED: string = 'warning.popup.cart.onebuyonly.has.removed'
const WARNING_CART_ONEBUYONLY_HAS_CONSUMED: string = 'warning.popup.cart.onebuyonly.has.consumed'

const getSessionStore = ({ reducers }: Store) => reducers[sessionReducerName]
const getIdentifiedLabel = (userLoggedIn: boolean, identifiedMode: boolean): number => {
    let identifiedLabel: number = 0
    if (userLoggedIn) {
        identifiedLabel = 2
    } else if (identifiedMode) {
        identifiedLabel = 1
    }
    return identifiedLabel
}

const log = logger.getLogger('AppSagas')

export function* fetchPromotions() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const promotions: Promotions = yield call(() => net.get(`${sessionConfig.urlPrefix}/rest/v1/promotionData`))
        if (promotions) {
            yield put({
                type: ActionTypes.FETCHED_PROMOTIONS,
                payload: {
                    promotions,
                },
            })
        }
    } catch (err) {
        log.error('Error in fetching promotions')
    }
}

export function* initApplication() {
    yield call(fetchInitConfig)
    if (isCampaign()) {
        const campaignId: string = `${getCampaignId()}${location.search}`
        yield activateCampaign(campaignId)
    }
    yield put(restoreAbandonedCart())
}

export function* fetchInitConfig() {
    const documentUrlPrefix: string = `${window['sessionConf'].urlPrefix}`
    const sessionConfig = yield select(selectSessionConfig)
    const urlPrefix: string = sessionConfig?.urlPrefix || documentUrlPrefix
    let fromScodQs: string = ''
    if (isPuppeteer) {
        fromScodQs += '&from_scod=true'
    }

    try {
        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: true,
            },
        })

        const data: InitRequestResult = yield call(() => net.get(`${urlPrefix}/rest/v1/page/init?${fromScodQs}`))

        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
        const cartData: CartDataResponse = data.cartData
        cartData.entries.forEach((entry: CartEntry) => {
            entry.product.imagesSubset = parseImageSubset(entry.product.imagesSubset, fallbackImage)
        })
        const cartDataPayload: SetCartDataPayload = {
            cartData,
            initCart: true,
        }
        yield put(setCartDataInStore(cartDataPayload))
        yield put({
            type: CartActionTypes.FETCH_CART,
        })

        if (data.crossSellProductCodes) {
            yield put({
                type: ProductActionTypes.FETCHED_CROSS_SELL_PRODUCT_CODES,
                payload: {
                    productCodes: data.crossSellProductCodes,
                },
            })
        }

        const promotions: Promotions = data.promotionData
        yield put({
            type: AppActionTypes.FETCHED_PROMOTIONS,
            payload: {
                promotions,
            },
        })

        if (data.scripts) {
            handleInitScripts(data.scripts, { ...sessionConfig, ...data.sessionConf })
        }

        const identifiedLabel: number = getIdentifiedLabel(data.sessionConf?.userLoggedIn, data.sessionConf?.identifiedMode)
        const appStore: AppStore = yield select(getSessionStore)
        const includeInAnalytics: boolean = appStore.previousIdentifiedGoogleAnalyticsLabel !== identifiedLabel
        if (includeInAnalytics) {
            yield put({
                type: TrackingActionTypes.SESSION_CONFIG_UPDATED,
                payload: {
                    gaCat: GoogleAnalyticsCategory.SESSION,
                    gaType: GoogleAnalyticsType.IDENTIFIED,
                    gaLabel: identifiedLabel,
                },
            })
        }

        const setConfigPayload: SetConfigPayload = {
            sessionConfig: data.sessionConf,
            googleAnalyticsLabel: identifiedLabel,
        }

        yield put({
            type: ActionTypes.SET_CONFIG,
            payload: setConfigPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: false,
            },
        })
    } catch (err) {
        yield put(
            addRestCallFailed({
                url: `${urlPrefix}/rest/v1/page/init`,
                method: HttpMethods.GET,
                error: err,
            }),
        )
    }
}

export function* updateSessionConfig() {
    try {
        const sessionConfig: SessionConfig = yield select(selectSessionConfig)
        const url: string = `${sessionConfig.urlPrefix}/rest/v1/session/config?sessionSvc`
        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: true,
            },
        })
        const data = yield call(() => net.get(url))
        const updatedSessionConfig: SessionConfig = {
            ...data.value,
        }
        if (data.value['csrfToken'] || data.value['CSRFToken']) {
            updatedSessionConfig.CSRFToken = data.value['csrfToken'] || data.value['CSRFToken']
        }
        const identifiedLabel: number = getIdentifiedLabel(updatedSessionConfig.userLoggedIn, updatedSessionConfig.identifiedMode)
        const appStore: AppStore = yield select(getSessionStore)
        const includeInAnalytics: boolean = appStore.previousIdentifiedGoogleAnalyticsLabel !== identifiedLabel

        if (includeInAnalytics) {
            yield put({
                type: TrackingActionTypes.SESSION_CONFIG_UPDATED,
                payload: {
                    gaCat: GoogleAnalyticsCategory.SESSION,
                    gaType: GoogleAnalyticsType.IDENTIFIED,
                    gaLabel: identifiedLabel,
                },
            })
        }

        const setConfigPayload: SetConfigPayload = {
            sessionConfig: updatedSessionConfig,
            googleAnalyticsLabel: identifiedLabel,
        }
        yield put({
            type: ActionTypes.SET_CONFIG,
            payload: setConfigPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: false,
            },
        })

        if (updatedSessionConfig.oneBuyOnlyOptionRemoved) {
            yield put(fetchMessageFromServer(WARNING_CART_ONEBUYONLY_HAS_REMOVED))
        }
        if (updatedSessionConfig.oneBuyOnlyOfferConsumed) {
            yield put(fetchMessageFromServer(WARNING_CART_ONEBUYONLY_HAS_CONSUMED))
        }
        if (data.storedTokenModificationMessageKey) {
            yield put(fetchMessageFromServer(data.storedTokenModificationMessageKey))
        }
        if (data.subscriptionProductThresholdReachedMessageKey) {
            yield put(fetchMessageFromServer(data.subscriptionProductThresholdReachedMessageKey))
        }
    } catch (error) {
        yield put(
            addRestCallFailed({
                url: '/rest/v1/session/config?sessionSvc',
                method: HttpMethods.GET,
                error,
            }),
        )
    }
}

export function* checkCustomerToBeLoggedOff({ payload }: any) {
    const { targetCountryUrl }: CheckCustomerToBeLoggedOffPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    const customerType: CustomerType = sessionConfig.b2bMode ? CustomerType.PRIVATE : CustomerType.COMPANY
    let postdata: string = `customerType=${customerType}`
    if (sessionConfig.currentBaseStoreId) {
        postdata += `&baseStoreId=${sessionConfig.currentBaseStoreId}`
    }
    try {
        const result: CheckCustomerResult = yield call(() =>
            net.post(`${sessionConfig.urlPrefix}/rest/v1/_s/checkCustomer`, postdata),
        )
        if (result?.logOffCustomer) {
            yield put({
                type: ActionTypes.SET_CUSTOMER_TYPE_CHANGE_WARNING,
                payload: {
                    showDialog: true,
                    messageText: result.messageText,
                    messageTitle: result.messageTitle,
                    popupTitle: result.popupTitle,
                    cancelText: result.cancelText,
                    logoutText: result.logoutText,
                    targetCountryUrl,
                },
            })
        } else if (!result?.logOffCustomer) {
            yield switchCustomerType()
        } else {
            const currentUrlResult: any = yield call(() => net.get(`${url}?currentURL=${window.location.pathname}`))
            const url: string = currentUrlResult.redirectURL
            // yield put(navigateTo({ url }))
            /**
             * DO NOT REMOVE window.location.href
             * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
             */
            window.location.href = url
        }
    } catch (err) {
        // fall back to switching customer type
        // do not switch the customer if there is some error in the network call
        addRestCallFailed({
            url: `${sessionConfig.urlPrefix}/rest/v1/_s/checkCustomer`,
            method: HttpMethods.POST,
            error: err,
        })
    }
}

export function* switchCustomerType() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.b2bMode ? '/rest/v1/_s/private' : '/rest/v1/_s/company'
    try {
        const redirect: RedirectResponse = yield call(() => net.get(`${sessionConfig.urlPrefix}${url}`))
        // yield put(navigateTo({ url: redirect.redirectFullURL }))
        /**
         * DO NOT REMOVE window.location.href
         * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
         */
        window.location.href = redirect.redirectFullURL
    } catch (err) {
        // Do nothing in case of an error
        log.error('Error occured during the customer switch')
        addRestCallFailed({
            url: `${sessionConfig.urlPrefix}/${url}`,
            method: HttpMethods.GET,
            error: err,
        })
    }
}

export function* logoutSwitchCustomerTypeOrChangeLanguage({ payload }: any) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    const customerType: CustomerType = sessionConfig.b2bMode ? CustomerType.PRIVATE : CustomerType.COMPANY
    const logoutURL: string = sessionConfig.currentBaseStoreId
        ? `${sessionConfig.urlPrefix}/logout`
        : `${sessionConfig.urlPrefix}/logout?customerType=${customerType}`
    try {
        /**
         * Log out customer
         */
        yield call(() => net.get(logoutURL))

        /**
         * If targetCountryUrl exists, change language else switch customer type
         */
        const { targetCountryUrl } = payload
        if (targetCountryUrl) {
            yield put(navigateTo({ url: targetCountryUrl }))
        } else {
            yield switchCustomerType()
        }
    } catch (err) {
        log.error('Error occured during the customer logout')
        addRestCallFailed({
            url: logoutURL,
            method: HttpMethods.GET,
            error: err,
        })
    }
}

export const watchers = [
    takeLatest(ActionTypes.FETCH_PROMOTIONS, fetchPromotions),
    takeLeading(ActionTypes.INIT_APPLICATION, initApplication),
    takeEvery(ActionTypes.UPDATE_SESSION_CONFIG, updateSessionConfig),
    takeLeading(ActionTypes.FETCH_INIT_CONFIG, fetchInitConfig),
    takeLeading(ActionTypes.CHECK_CUSTOMER_TO_BE_LOGGED_OFF, checkCustomerToBeLoggedOff),
    takeLeading(ActionTypes.LOG_OUT_SWITCH_CUSTOMER_TYPE_OR_CHANGE_LANGUAGE, logoutSwitchCustomerTypeOrChangeLanguage),
]
