// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    linkColor: '#000',
    linkColorHover: '#000',
    conf: {
      showSideNavigationOnStaticPages: true,
      showHomepageCategorySection: false,
    },
    sideContentPadding: 'px-4',
  };
  return res;
};

export default themeGen;
