import React, { Fragment } from 'react';
import Accordion from 'ic/ui-elem/AccordionMod.jsx';
import AccordionItem from 'ic/ui-elem/AccordionItem.jsx';
import Link from 'ic/ui-elem/Link';
import * as cls from './Footer.styles';
import classNames from 'classnames';

const FooterLinksForMobile = (p) => (
  <div className={classNames('w-full md:hidden', cls['footer-mobile'])}>
    {p.nodes ? (
      <ul className={classNames(cls['footer-nodes'], 'm-0')}>
        {p.nodes.map((item) => (
          <Accordion key={'accordion_' + item.uid} iid={'footerNodes'} openFromStart={p.theme.defaultOpenFromStart}>
            {item.nodes.map((innerItem) =>
              innerItem.nodes && innerItem.nodes.length > 0 ? (
                <AccordionItem
                  key={'accordionitem_' + innerItem.uid}
                  title={innerItem.title}
                  className={'pb-4'}
                  iconColor={p.theme.footerIconColor}
                >
                  <li className={classNames('footer-nodes-li pl-2 pt-4')}>
                    <ul className={classNames(cls.Fb, 'm-0')}>
                      {innerItem.nodes
                        .filter((link) => link.url)
                        .map((link) => (
                          <li key={link.url} className={'e2e-ver-url' + link.url.replaceAll('/', '-')}>
                            <Link to={link.url} external={link.external} target={link.target} linkTitle={link.title} />
                          </li>
                        ))}
                    </ul>
                  </li>
                </AccordionItem>
              ) : (
                <Fragment key={innerItem}>
                  <li className="footer-nodes-li no-padding flex justify-center">
                    <ul className={classNames(cls.Fb, 'm-0')}>
                      {innerItem.description && innerItem.image ? (
                        <li className={classNames(cls['content-Fb'], 'px-4')}>
                          <h4 className={'pb-2'}>{innerItem.title}</h4>
                          <img alt={innerItem.image.altText} src={innerItem.image.url} />
                          <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                        </li>
                      ) : null}
                      {!innerItem.image && innerItem.description ? (
                        <li className={classNames(cls['content-Fb'], 'px-4')}>
                          <h4 className={'pb-2'}>{innerItem.title}</h4>
                          <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                        </li>
                      ) : null}
                    </ul>
                  </li>
                </Fragment>
              )
            )}
          </Accordion>
        ))}
      </ul>
    ) : null}
  </div>
);

export default FooterLinksForMobile;
