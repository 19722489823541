import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, setupMutations, areStatePropsEqual, mapService } from 'core/util/module-utils';
import logger from 'score/logSvc';
import NewsLetter from 'eco/NewsLetter/NewsLetterMod.jsx';
import { getters as navigationSvcGetters, svcData as navData } from 'seco/navigationSvc/navigationSvcMod';
import { str } from 'seco/localeSvc';
import classnames from 'classnames';
import { getThemeFactory } from '@ic-theme';
import FooterLinksForDesktop from './FooterLinksForDesktop.jsx';
import FooterLinksForMobile from './FooterLinksForMobile.jsx';
import ContentSlot from 'eco/ContentSlot/ContentSlotMod.jsx';
import * as cls from './Footer.styles';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'Footer';
const th = getThemeFactory(name, {
  footerBackgroundColor: '#004b6a',
  footerForegroundColor: 'white',
  footerIconColor: 'secondary-alt',
  conf: {
    showNewsletterSection: false,
  },
  defaultOpenFromStart: false,
});

const theme = th();

// Mudules data, this is the initial data for an instance
const initialState = {
  footerTopNavigationNode: { nodeLinks: [] },
  footerBottomNavigationNode: { nodeLinks: [] },
  notice: '',
};

const log = logger.getLogger(name); // eslint-disable-line
// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  mapService(navigationSvcGetters(state, ownProps), instance);
  // Trigger reload in case of difference in strings
  instance.emailAdressLabel =
    state.serviceData.localeSvc && state.serviceData.localeSvc.strings
      ? state.serviceData.localeSvc.strings['phrase.enter_your_email_here']
      : '...';
  return instance;
};

// ################# ACTIONS  #################

const actions = () => ({});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {};
/* eslint-enable */

// ################# MODULE SETUP DONT TOUCH  #################
const conf = { multipleInstances, name, initialState };
export const _module = {
  name, state: setupInitialState(initialState, conf), actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};
// ################# RENDER  #################
class FooterMod extends React.Component {
  componentDidMount() {}

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const newsLetterHeading =
      str('newsletter.title') !== 'newsletter.title'
        ? str('newsletter.title')
        : str('phrase.exclamation.recieve_our_news_letter');
    const newsLetterSubHeading =
      str('newsletter.subtitle') !== 'newsletter.subtitle'
        ? str('newsletter.subtitle')
        : 'Anmäl dig till vårt nyhetsbrev och få exklusiva erbjudanden direkt i inkorgen.';
    const nodes = navData.footerNodes ? navData.footerNodes : [];
    const pageId = this.props.pageId;
    return (
      <footer
        style={{ background: theme.footerBackgroundColor, color: theme.footerForegroundColor }}
        className={classnames('w-full ml-0 print:hidden', cls.footer)}
      >
        <div className={'flex flex-col lg:flex-row w-full print:hidden e2e-ver-footer-nodes'}>
          <div className={classnames(cls.links, 'flex-1')}>
            <FooterLinksForDesktop nodes={nodes} />
            <FooterLinksForMobile theme={theme} className={cls.responsive} nodes={nodes} />
          </div>
          {pageId !== undefined ? (
            <div className={classnames('flex-0 px-2 lg:pt-6 lg:pr-2 e2e-ver-right-footer-content', cls['right-footer-content'])}>
              <ContentSlot
                iid={pageId + '---RightFooter'}
                key={pageId + '---RightFooter'}
                SSRKey={pageId + '---RightFooter'}
                pageId={pageId}
                position={'RightFooter'}
              />
            </div>
          ) : null}
        </div>
        {theme.conf.showNewsletterSection ? (
          <div className={classnames(cls.newsletter, 'my-4 p-4 flex flex-col lg:flex-row justify-center e2e-ver-newsletter')}>
            <div className={'w-full lg:w-2/5'}>
              <div className={'font-bold'}>{newsLetterHeading}</div>
              <div>{newsLetterSubHeading}</div>
            </div>
            <div className={classnames('w-full lg:w-3/5', cls['newsletter-input-box'])}>
              <NewsLetter color={'primary'} {...this.props} />
            </div>
          </div>
        ) : null}
        {pageId !== undefined ? (
          <div className={'my-4 e2e-ver-footer-payment-options'}>
            <ContentSlot
              iid={pageId + '---BottomFooter'}
              key={pageId + '---BottomFooter'}
              SSRKey={pageId + '---BottomFooter'}
              pageId={pageId}
              position={'BottomFooter'}
            />
          </div>
        ) : null}
        {this.props.notice ? <div className={cls.notice}>{this.props.notice}</div> : null}
      </footer>
    );
  }
}
FooterMod.contextTypes = { store: PropTypes.object };
const ExportedFooter = connect(getters, actions, undefined, {
  areStatePropsEqual,
})(FooterMod);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default ExportedFooter;
