const themeGen = theme => { // eslint-disable-line
  const res = {
    excludeSameProductFromShelf: false,
    hideShelfTitleOnMobile: false,
    showPrinterModelRecommendation: true,
    showVaccumModelRecommendation: false,
    conf: {
      shelfPageProductsOrdering: ['oneBuyProducts', 'recommended', 'original', 'freeCategories'],
      shelfPageCheckProductSection: ['recommended', 'optimum', 'budget', 'original'],
    },
  };
  return res;
};

export default themeGen;
