const themeGen = theme => ({  // eslint-disable-line
  iconButtonMode: 'inverted',
  // plusIcon: 'plus-circle',
  // minusIcon: 'minus-circle',
  // roundIcon: true,
  productNameWidth: 'ic-col-5',
  productNameWidthExceptCartPage: 'ic-col-6',
  totalColumnWidth: 'ic-col-1',
  totalColumnWidthExceptCartPage: 'ic-col-2',
  iconSize: 'md',
  showProductDetails: true,
  quantityButtonColor: 'secondary',
  showPerPiecePriceTitle: true,
  conf: {
    showPerKronaInCartTable: false,
  },
  countButtonColor: 'tertiary',
});

export default themeGen;
