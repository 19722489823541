import { ActionTypes } from './constants'
import {
    AddToCart,
    AddSubscriptionToCartPayload,
    SetCartDataPayload,
    FetchCartPayload,
    UpdateCartEntryPayload,
} from './interfaces'

export const addToCart = (payload: AddToCart) => ({
    type: ActionTypes.ADD_TO_CART,
    payload,
})

export const fetchCart = (payload: FetchCartPayload = {}) => ({
    type: ActionTypes.FETCH_CART,
    payload,
})

export const updateCartEntry = (payload: UpdateCartEntryPayload) => ({
    type: ActionTypes.UPDATE_CART_ENTRY,
    payload,
})

export const fetchRecommendedCart = () => ({
    type: ActionTypes.FETCH_RECOMMENDED_CART,
})

export const fetchOrderConfirmation = (orderId: string) => ({
    type: ActionTypes.FETCH_ORDER_CONFIRMATION,
    payload: {
        orderId,
    },
})

export const setCartDataInStore = (payload: SetCartDataPayload) => ({
    type: ActionTypes.SET_CART_DATA_IN_STORE,
    payload,
})

export const addSubscriptionProductToCart = (payload: AddSubscriptionToCartPayload) => ({
    type: ActionTypes.ADD_SUBSCRIPTION_PRODUCT_TO_CART,
    payload,
})

export const restoreAbandonedCart = () => ({
    type: ActionTypes.RESTORE_ABANDONED_CART,
})

export const rejectAbandonedCart = () => ({
    type: ActionTypes.REJECT_ABANDONED_CART,
})

export const resetSubscriptionInterval = () => ({
    type: ActionTypes.RESET_SUBSCRIPTION_INTERVAL,
})

export const setSubscriptionInterval = (subscriptionIntervalCode: string) => ({
    type: ActionTypes.SET_SUBSCRIPTION_INTERVAL,
    payload: {
        subscriptionIntervalCode,
    },
})
