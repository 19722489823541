import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import logger from 'score/logSvc';
import { setupInitialState, mapItems, setupMutations, areStatePropsEqual } from 'core/util/module-utils';
import { getThemeFactory } from '@ic-theme';
import StructuredSEOData from 'deco/StructuredSEOData';
import cleanKey from 'core/util/cleanKey';
import classNames from 'classnames';

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'Accordion';

const th = getThemeFactory(name, {
  openIcon: "'\f105'", // eslint-disable-line
  closeIcon: "'\f107'", // eslint-disable-line
});

// Mudules data, this is the initial data for an instance
const initialState = {
  multiE: false,
  initialized: false,
};

const log = logger.getLogger(name); // eslint-disable-line
const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));

  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => ({
  open: (id) => dispatch({ type: 'ACCORDION_OPEN', iid: ownProps.iid, item: id }),
  close: (id) => dispatch({ type: 'ACCORDION_CLOSE', iid: ownProps.iid, item: id }),
  initialValues: (multiE, openFromStart, activeItem) =>
    dispatch({ type: 'ACCORDION_INITIALISATION', noAnalytics: true, iid: ownProps.iid, multiE, openFromStart, activeItem }),
});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  ACCORDION_OPEN: (state, action) => {
    if (state.multiE) {
      state.activeItem.push(action.item);
    } else {
      state.activeItem = [action.item];
    }
  },
  ACCORDION_CLOSE: (state, action) => {
    if (state.multiE) {
      state.activeItem.splice(state.activeItem.indexOf(action.item), 1);
    } else {
      state.activeItem = [];
    }
  },
  ACCORDION_INITIALISATION: (state, action) => {
    state.multiE = action.multiE;
    if (action.openFromStart) {
      state.activeItem = [1, 2, 3];
    } else if (!action.openFromStart && action.activeItem && action.activeItem.length > 0) {
      state.activeItem = action.activeItem;
    } else {
      state.activeItem = [];
    }
    state.initialized = true;
  },
};
/* eslint-enable */

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name, state: setupInitialState(initialState, conf), actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};

const compileQuestionFromItem = (item) => {
  return {
    '@type': 'Question',
    name: item.properties.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.properties.content,
    },
  };
};
const compileSeoFAQDataFromAccordion = (items) => {
  const seoData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };
  items.forEach((item) => {
    if (item.componentType === 'AccordionItem') {
      const _item = compileQuestionFromItem(item);
      if (_item.acceptedAnswer.text && _item.name) {
        seoData.mainEntity.push(_item);
      }
    }
  });

  log.info('FAQ data', items, seoData);

  return seoData;
};

class Accordion extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      children: PropTypes.node,
      activeItem: PropTypes.any,
      /** Number of columns at when in full width. This will be rediced as size grows more narrow. */
    };
  }

  componentDidMount() {
    this.props.initialValues(this.props.multiExpand, this.props.openFromStart, this.props.activeItem);
  }

  render() {
    let accIndex = 0;
    // eslint-disable-next-line no-unused-vars
    const theme = th();
    let seoData;
    if (this.props.items) {
      seoData = compileSeoFAQDataFromAccordion(this.props.items);
    }
    return (
      <div
        className={classNames(
          this.props.elementUid ? 'smartEditComponent' : '',
          this.props.className ? ' ' + this.props.className : ''
        )}
        data-ssr-key={this.props.SSRKey}
        data-smartedit-component-id={cleanKey(this.props.SSRKey)}
        data-smartedit-component-uuid={cleanKey(this.props.elementUid)}
        data-smartedit-catalog-version-uuid={cleanKey(this.props.contentSlotCatalogVersion)}
        data-smartedit-component-type={cleanKey(this.props.componentParentType)}
      >
        {this.props.items ? <StructuredSEOData data={seoData} /> : null}
        <div className={'e2e-ver-accordion'}>
          {React.Children.map(this.props.children, (child) => {
            accIndex += 1;
            return React.cloneElement(child, {
              key: accIndex,
              accIndex,
              open: this.props.open,
              close: this.props.close,
              activeItem: this.props.activeItem,
              initialized: this.props.initialized,
            });
          })}
        </div>
      </div>
    );
  }
}

Accordion.defaultProps = {
  columns: 1,
  multiExpand: false,
  openFromStart: false,
};

export default connect(getters, actions, undefined, {
  areStatePropsEqual,
})(Accordion);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();