import React from 'react';
import PropTypes from 'prop-types';
import net from 'score/networkSvc';
import logger from 'score/logSvc';
import browserSvc from 'score/browserSvc';
import priceFormatter from 'seco/localeSvc/priceFormatter';
import addressFormatter from 'seco/localeSvc/addressFormatter';
import serverPreLoad from 'seco/localeSvc/serverPreLoad';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import forEach from 'lodash/forEach';
import getStore from '@spa-core/redux/store';
import __DEV__ from '__DEV__';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const log = logger.getLogger('localeSvc');

let _DebugMode = false;
if (typeof location !== 'undefined') {
  _DebugMode = location.href.match('show-string-keys') || location.href.match('show-keys');
  if (_DebugMode) {
    browserSvc.sessionSet('str-debug-mode', _DebugMode);
  } else if (browserSvc.sessionGet('str-debug-mode') === 'true') {
    _DebugMode = true;
  }
}

export const evalLocale = (locale) => {
  if (typeof locale === 'undefined') {
    const sessionConfig = getReducer(sessionReducerName).sessionConfig;
    const _locale = sessionConfig.currentLanguageIsocode || 'sv';
    const checkSpecialISO = _locale.match('([a-z]{2})_(?:[A-Z]{2})');
    if (checkSpecialISO) {
      return checkSpecialISO[1];
    }
    return _locale;
  }
  return locale;
};

export class localeSvc extends React.Component {
  constructor() {
    super();
    this.stringsDb = {};
  }

  /**
   * Initializing the locale service for a specific store. In practive this includes fetching
   * the translations from the server and adding them to this specific store. It might be
   * more correct to say that the the locale service initialized the store and the stae with
   * correct language strings.
   * @param {Object} _store - The store for the session.
   */
  init(_store) {
    if (!_store) {
      return;
    }

    if (browserSvc.inBrowser()) {
      // Only fetch the strings via the webservice if the strings ws not provided by the serverside rendering already
      if (!_store.getState()?.serviceData?.localeSvc?.strings) {
        this.fetchData(_store);
      }
    } else {
      // On server side - read data form file and store in state
      const state = _store.getState();
      // The value originates from the server side where it was passed to the server side rendering function
      // by the react tag then the locale is set into the state by the reducer (still at the server side).
      const locale = state.serviceData.localeSvc.locale;

      if (!this.stringsDb[locale]) {
        const pathToUi = '../../../custom/inkclub/inkclubstorefront/web/webroot/_ui';
        const localeObj = serverPreLoad(`${pathToUi}/dist/locale/base_${locale}.json`);
        _store.dispatch({
          type: 'LOCALE_STRINGS',
          data: localeObj,
          locale,
        });
        this.stringsDb[locale] = localeObj;
      } else {
        _store.dispatch({
          type: 'LOCALE_STRINGS',
          data: this.stringsDb[locale],
          locale,
        });
      }
    }
  }

  /**
   * Function that gets all the strings and properties according to the predefined rules for a specific locale. If no
   * locale is provided the locale provided by hybris is used.
   * @param {Object} store - The redux store for the session.
   */
  fetchData(_store) {
    const sessionConfig = getReducer(sessionReducerName)?.sessionConfig || window.sessionConf;
    let strings;
    try {
      strings = JSON.parse(browserSvc.sessionGet(`LOCALE_STRINGS_${sessionConfig.currentLanguageIsocode}`));
    } catch (exception) {
      strings = null;
    }

    const self = this;
    const fetcher = (res) => {
      try {
        try {
          browserSvc.sessionSet(`LOCALE_STRINGS_${sessionConfig.currentLanguageIsocode}`, JSON.stringify(res));
        } catch (exc) {
          log.debug(exc);
        }
        self.stringsDb[sessionConfig.currentLanguageIsocode] = res;
        _store.dispatch({
          type: 'LOCALE_STRINGS',
          data: res,
          locale: sessionConfig.currentLanguageIsocode,
        });
      } catch (err) {
        log.error('Error: ', err);
      }
    };

    if (strings === null) {
      net.get(`${sessionConfig.contextPath}/_ui/dist/locale/base_${sessionConfig.currentLanguageIsocode}.json`).then(fetcher);
    } else {
      this.stringsDb[sessionConfig.currentLanguageIsocode] = strings;
      _store.dispatch({
        type: 'LOCALE_STRINGS',
        data: strings,
        locale: sessionConfig.currentLanguageIsocode,
      });
    }
  }

  /**
   * Function that formats a string as per the defined property from localeSVC
   * @param {Object} store - the redux store
   * @param {string} strKey - key of the property
   * @param {string[]} args - Arguments that the property takes
   * @returns {string} A translated string containing the argumented property value.
   * If no stranslation was fount the identifier is returned. If no translations at all
   * was found an empty string is returned.
   */
  toStr(_store, strKey, args, defStr) {
    // debugger; // eslint-disable-line
    // If dev is true set the strKey as default retVal - else '' as it used to be
    const retVal = __DEV__ ? defStr || strKey : '';
    if (!_store || !_store.getState) {
      return retVal;
    }
    // get the state fromt he store
    const state = _store.getState();

    if (typeof state === 'undefined') {
      return '';
    }
    if (typeof state.serviceData === 'undefined') {
      return '';
    }

    // At this point at state has been extracted fromt he arguments or the current object
    const strings = state.serviceData.localeSvc.strings;
    const siteStrings = state.serviceData.siteLocaleSvc.strings;

    // Find the translation for the string identifier
    let rawString = '';

    if (siteStrings && siteStrings[strKey]) {
      rawString = siteStrings[strKey];
    }

    // Ignore this if match was found in site specific strings
    if (!rawString && strings) {
      if (strings[strKey]) {
        rawString = strings[strKey];
      } else if (args && defStr) {
        return this.processArgsOnRawString(args, defStr);
      } else {
        // No translation is found, return the original identifier
        return strKey;
      }
    }

    // No object with translations has been found return an empty string instead
    // of the original identifer. This avoid the whole gui to be messed up with odd strings
    if (!strings) {
      return '';
    }

    // Check if some arguments are to be added to the string
    if (!args) {
      return rawString;
    }

    // Check the arguments and replace the placeholders with values
    rawString = this.processArgsOnRawString(args, rawString);

    return rawString;
  }

  processArgsOnRawString(args, rawString) {
    let resRawString = rawString;
    if (isArray(args)) {
      forEach(args, (argument, i) => {
        const reStr = `\\{${i}\\}`;
        const re = RegExp(reStr, 'g');
        if (isNumber(args[i]) || isString(args[i])) {
          resRawString = resRawString.replace(re, args[i]);
        } else {
          resRawString = resRawString.replace(re, '[Object]');
        }
      });
    }
    return resRawString;
  }

  /**
   * Function that returns the path to image as per the defined image name and locale
   * @param {Object} store - the redux store
   * @param {string} strKey - name of the image
   * @returns {string} A translated string containing the name of the image with the locale appended
   */
  localizedImageName(_store, strKey) {
    if (!_store || !_store.getState) {
      return '';
    }
    // get the state fromt he store
    const state = _store.getState();

    // At this point at state has been extracted fromt he arguments or the current object
    const strings = state.serviceData.localeSvc.locale;

    if (strings) {
      return `${strKey}_${strings}`;
    }
    return '';
  }

  /**
   * Function that formats a price according to the predefined rules for a specific locale. Of no locale is provided the
   * locale provided by hybris is used.
   * @param price - A number for the price to be formatted.
   * @param _locale - The locale to use if you want to override the hybris locale otherwise undefined.
   * @returns A string containing the formatted price according to the long formatting rules.
   */
  formatDecPrice(price, _locale) {
    let locale = _locale;

    if (typeof _locale === 'undefined') {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      locale = sessionConfig.currentLanguageIsocode || 'sv';
    }
    if (price === undefined || price === null) {
      return price;
    }
    return priceFormatter.dec(price, locale);
  }

  /**
   * Function that formats a price according to the predefined rules for a specific locale. Of no locale is provided the
   * locale provided by hybris is used.
   * @param price - A number for the price to be formatted.
   * @param _locale - The locale to use if you want to override the hybris locale otherwise undefined.
   * @returns A string containing the formatted price according to the no currency formatting rules.
   */
  formatNoCurrencyPrice(price, _locale) {
    let locale = _locale;

    if (typeof _locale === 'undefined') {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      locale = sessionConfig.currentLanguageIsocode;
    }
    return priceFormatter.noCurrency(price, locale);
  }

  /**
   * Function that formats a price according to the predefined rules for a specific locale. Of no locale is provided the
   * locale provided by hybris is used.
   * @param price - A number for the price to be formatted.
   * @param _locale - The locale to use if you want to override the hybris locale otherwise undefined.
   * @returns A string containing the formatted price according to the long formatting rules.
   */
  formatLongPrice(price, _locale) {
    let locale = _locale;
    if (typeof _locale === 'undefined') {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      locale = sessionConfig.currentLanguageIsocode;
    }
    if (price === undefined || price === null) {
      return price;
    }
    return priceFormatter.long(price, locale);
  }

  /**
   * Function that formats a price according to the predefined rules for a specific locale. Of no locale is provided the
   * locale provided by hybris is used.
   * @param {number} price - A number for the price to be formatted.
   * @param {string} _locale - The locale to use if you want to override the hybris locale otherwise undefined.
   * @returns A string containing the formatted price according to the short formatting rules.
   */
  formatShortPrice(price, _locale) {
    let locale = _locale;

    if (typeof _locale === 'undefined') {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      locale = sessionConfig.currentLanguageIsocode;
    }
    return priceFormatter.short(price, locale);
  }

  formatDate(date, _locale) {
    return date.toLocaleDateString(evalLocale(_locale));
  }

  /**
   * Function that formats a address according to the predefined rules for a specific locale. If no locale is provided the
   * locale provided by hybris is used.
   * @param {string} line1 - line1 for the address
   * @param {string} line2 - line2 for the address
   * @param {string} publicKey - publicKey for the address
   * @param {string} postalcode - postalcode for the address
   * @param {string} town - town for the address
   * @param {string} countryName - countryName for the address
   * @param {string} _locale - The locale to use if you want to override the hybris locale otherwise undefined.
   * @returns A string containing the formatted address according to the locale specific rules.
   */
  formatAddress(line1, line2, publicKey, postalcode, town, countryName, _locale) {
    let locale = _locale;
    if (typeof _locale === 'undefined') {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      locale = sessionConfig.currentLanguageIsocode;
    }
    return addressFormatter.format(line1, line2, publicKey, postalcode, town, countryName, locale);
  }

  setDebugMode(val) {
    _DebugMode = val;
    browserSvc.sessionSet('str-debug-mode', val);
  }
}

localeSvc.contextTypes = {
  store: PropTypes.object,
};
const service = new localeSvc();

export default service;

export const str = (strKey, args) => {
  if (!service.toStr) {
    return;
  }
  return _DebugMode ? strKey : service.toStr(getStore(), strKey, args);
};

export const strAsHTML = (input) => {
  // eslint-disable-next-line react/jsx-filename-extension
  return typeof input === 'string' ? <span dangerouslySetInnerHTML={{ __html: input }} /> : input;
};
export const strDef = (key, def) => {
  const lStr = str(key);
  if (lStr === key) {
    return _DebugMode ? key : def;
  }
  return _DebugMode ? key : lStr;
};

export const strDefWithArgs = (key, args, defStr) => {
  if (!service.toStr) {
    return defStr;
  }
  const lStr = _DebugMode ? key : service.toStr(getStore(), key, args, defStr);
  if (lStr === key) {
    return key;
  }
  return lStr;
};
