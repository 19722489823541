/**
 * # theme.conf.js
 * This is the base file used by the the themeing system. Each
 * theme in turn has its own theme.conf file but all attributes
 * in a child theme.conf needs to defined here. If not other
 * themes using that property will fail.
 * @name $root/theme.conf.js
 */
/**
 * ## Palette
 * Each theme.conf file should have a palette object defining the
 * colors used by the theme. A note about the palette is that is
 * should contain colors without semantic connection. So for instance
 * a color named discountColor would be in the wrong place.
 *
 * ```js
 * discountColor: '#ff6666',
 * ```
 */
/**
 * Instead the color could be named sunsetRed.
 *
 * ```js
 * sunsetRed: '#ff6666',
 * ```
 *
 * The connection to semantics is done in the actual theme. Read
 * further for the rest of the example continues.
 */
const palette = {
    darkBrown: '#3b210a',
    minespressoBlack: '#23130d',
    ristrettoBlack: '#434343',
    espressoBrown: '#82553f',
    ekoGreen: '#5eaf76',
    ekoGreenShade: '#458a58',
    lungoBlue: '#3a5c8f',
    meDark: '#23130d',
    meBrown: '#3b210a',
    meLightBrown: '#725441',
    meLightBrownShade: '#5c3f2e',
    meCream: '#f5e7d9',
    meDarkCream: '#bfa184',
    meGrayCream: '#baa9a0',
    meGray: '#707070',
    lightGray: '#e1e1e1',
    red: '#f84239',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient4: '#525252',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradienta: '#aeaeae',
    blackGradientb: '#b2b2b2',
    blackGradientf: '#f4f4f4',
    offBlack: '#323536',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    successGreen: '#7fc142',
    successLightGreen: '#f6f8b4',
    error: '#f15c4a',
    errorLight: '#ffe5e6',
};
/**
 * ## theme
 * Next part of the theme.conf file is the actual theme object.
 * **IMPORTANT** This should only be used for global properties. Properties only used by one component/module **must** not be defined here.
 *
 * That path would lead to an unruly theme where it is hard to understand what the properties are used for and hard to modify the properties.
 *
 * It is better to err in the direction of duplicating properties in several components then having properties used only by one component in the global.
 *
 *
 */
const theme = {
    /** The palette is part of the theme object
     * @option {Object} palette
     */
    palette,
    /** The background color to be used below contentent as text
     * @option {string} mainBg
     */
    mainBg: '#ffffff',
    /** The color of the text, it can of differ but this would be the default color of the text in the body
     * @option {string} textColor
     */
    textColor: '#333',
    /** The default padding/margin in rem. **Avoid** setting paddings with
     * @option {string} space
     */
    space: 0.735,
    /** The border radius to be used by buttons and panels etc
     * @option {string} radius
     */
    radius: '0',
    /**
     * ## theme.col
     * Here colors from the palette is used to define semantic meaning.
     * There are some standard combination used in the theme defined here.
     * The reson for this is to give the theme a strict use of colors,
     * simplify and speedup delopment.
     * * primary
     * * secondary
     * * tertiary
     * * info
     * * error
     * * pale
     * * spash
     * @option {object} col
     */
    col: {
        primary: {
            alt: 'yellow',
            fg: 'black',
            peer: 'orange',
        },
        secondary: {
            alt: 'white',
            fg: '#725441',
            peer: '#725441',
        },
        tertiary: {
            alt: 'white',
            fg: '#725441',
            peer: '#725441',
        },
        panel: {
            alt: '#725441',
            fg: '#f5e7d9',
            peer: '#f5e7d9',
        },
        info: {
            alt: 'white',
            fg: '#5eaf76',
            peer: '#5eaf76',
        },
        error: {
            alt: 'white',
            fg: '#5eaf76',
            peer: '#5eaf76',
        },
        pale: {
            alt: 'white',
            fg: '#5eaf76',
            peer: '#5eaf76',
        },
        splash: {
            alt: palette.offWhite,
            fg: '#00b0cd',
            peer: '#1790a7',
        },
        puff: {
            alt: 'white',
            fg: '#00b0ed',
            peer: '#009cd7',
        },
        highlight: {
            alt: palette.offWhite,
            fg: '#00b0cd',
            peer: '#1790a7',
        },
        selected: {
            alt: palette.offWhite,
            fg: '#00a651',
            peer: '#a3d39c',
        },
        /**
         * ## theme.col.vars
         * In col we have the child object vars. These are more specific then the primary, secondare etc defined earlier in col.
         * To continue the example from above where we want to define the discountColor but should not do it directly in the palette.
         *
         * This is the place to do it.
         * ```js
         *  discountColor: palette.sunsetRed,
         * ```
         * Then the color could would be fetched from the the theme using the follwowing path.
         * theme.col.vars.discountColor
         *
         * @option {Object} col.vars
         */
        vars: {
            // The background styling for whole body
            bodyBg: 'white',
            // The background styling for main content
            // Todo OG: rename to contentBg
            mainBg: 'white',
            text: palette.minespressoBlack,
            altText: palette.offBlack,
            link: palette.minespressoBlack,
            priceDiscountActive: palette.red,
            priceDiscountOverruled: '#999',
            success: palette.successGreen,
            successLight: palette.successLightGreen,
            error: palette.error,
            errorLight: palette.errorLight,
            // Todo SG: move to header component
            header: '#23130d',
        },
    },
    /**
     * ## theme.size
     * There are where the resposivity breakpoints are defined.
     */
    size: {
        xs: 415,
        sm: 580,
        md: 899,
        lg: 1218,
    },
    /**
     * ## theme.font
     * Font definitions like **theme.font.family** and **theme.font.header.family**
     */
    font: {
        family: 'Arial, Helvetica, sans-serif',
        header: {
            family: 'ICGravostyleBold, Arial, Helvetica, sans-serif',
        },
        special: {
            family: 'ICGravostyleBold, Arial, Helvetica, sans-serif',
        },
        /**
         * A sub section in font where the font sizes are defined
         * @option {string} font.size
         */
        size: {
            /** @option {string} font.size.x4 */
            x4: '32px',
            /** @option {string} font.size.x3 */
            x3: '24px',
            /** @option {string} font.size.x2 */
            x2: '22px',
            /** @option {string} font.size.x1 */
            x1: '20px',
            /** This is the default font size
             * @option {string} font.size.m */
            m: '17px',
            /** @option {string} font.size.s0 */
            s1: '15px',
            /** @option {string} font.size.s1 */
            s2: '12px',
            /** @option {string} font.size.s2 */
            s3: '10px',
            /** @option {string} font.size.s2 Extremly small size, smalled readable */
            s4: '7px',
        },
    },
    /**
     * ## theme.conf
     * Here we define theme properties used by the front end code
     * for modifying the functionality for a site. */
    conf: {
        placeholderImageSrc: 'images/placeholder.svg',
        showBreadCrumbs: false,
        // Todo: Move to CartTable/CartMod theme
        showBrand: false,
        // Todo move to PDPImages and Card
        showFavourite: false,
        /**
         * When the site have white background in the body you dont want paddings to the content block, the padding
         * makes it look wobbly, fort instavce cart page and footer vs content.
         *
         * When the site hos non-white background the padding is required, se dammsugarpasar cart page.
         * @option {string} paddingInContentBlock
         */
        paddingInContentBlock: true,
        uniformContentBlock: true,
        /** Should the CartSummary/CartTable show a link to the PDPPage.
         * @option {string} showPDPLink
         */
        showPDPLink: false,
        /** Is the theme using page titles.
         * @option {string} showTitle
         */
        showTitle: false,
    },
};
theme['padding'] = theme.space + 'rem';
theme['double-padding'] = theme.space * 2 + 'rem';
theme['half-padding'] = theme.space * 0.5 + 'rem';
export default theme;
