const themeGen = theme => ({  // eslint-disable-line
  widthTrygehandelNode: '25%',
  footerBackgroundColor: '#027A13',
  footerForegroundColor: 'white',
  conf: {
    showNewsletterSection: true,
  },
});

export default themeGen;
