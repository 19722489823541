import React from 'react';
import { UnmountClosed, Collapse } from 'react-collapse';
import * as cls from './InputSlider/InputSlider.styles';
import classNames from 'classnames';

export const SlideInOut = (p) => {
  // Todo add duration
  const duration = p.duration ? p.duration : 600;
  const overflow = p.overflow ? p.overflow : 'initial';
  const height = p.height ? p.height : 'auto';
  const ownStyles = {
    transitionProperty: 'height',
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: 'cubic-bezier(0.87, 0, 0.13, 1)',
    transitionDelay: '0s',
    overflow: `${overflow}`,
    height: `${height}`,
  };
  const parentStyles = p.styles ? p.styles : {};
  const styles = Object.assign(ownStyles, parentStyles);
  if (p.minHeight) {
    styles.minHeight = p.minHeight;
  }
  const Slide = p.unountClosed ? UnmountClosed : Collapse;
  return (
    <div className={classNames('print:hidden', cls['slide-in-out'])}>
      <Slide initialStyle={styles} isOpened={p.isOpened}>
        {p.children}
      </Slide>
    </div>
  );
};

export default SlideInOut;
