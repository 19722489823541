import { Certifications, Image, StockStatus, Product, SlimProduct, Images } from './interfaces'
import { getThemeFactory } from '@ic-theme'
import { STR_HAS_HIGHLIGHT_PATTERN_REGEX } from './constants'
import { str } from '@spa-ec-js/services/localeSvc'

export const parseImageSubset = (imagesSubset: Images, fallbackImage: string): Images => {
    if (!imagesSubset) {
        imagesSubset = {
            small: [],
            medium: [],
            large: [],
            xlarge: [],
        }
    }
    imagesSubset.primary = {
        small: imagesSubset.small.length > 0 ? imagesSubset.small[0] : { url: fallbackImage },
        medium: imagesSubset.medium.length > 0 ? imagesSubset.medium[0] : { url: fallbackImage },
        large: imagesSubset.large.length > 0 ? imagesSubset.large[0] : { url: fallbackImage },
        xlarge: imagesSubset.xlarge.length > 0 ? imagesSubset.xlarge[0] : { url: fallbackImage },
    }
    return imagesSubset
}

export const parseProduct = (productData: any, themeResourcePath: string): Product => {
    const {
        classifications,
        price,
        costPerPage,
        discountPrice,
        basePrice,
        name,
        url,
        title,
        code,
        manufacturer,
        description,
        trialProduct,
        oneBuyOnlyProduct,
        oneBuyUserGroupId,
        discountPercentage,
        productInfoMessage,
        oneBuyOnlyPromotionPrice,
        virtualShelfPageId,
        productType,
        unitsPerPackage,
        imagesSubset,
        stockStatus,
        status,
        productQuantityThreshold,
        subscribable,
        correspondsTo,
        thumbnailUrl,
        bracketPrices,
        specification,
        colorCode,
        color,
        manufacturerProductNumber,
        breadcrumbs,
        printerModelCategoryCode,
        modelCategoryCode,
        metaTitle,
        metaDescription,
        keywords,
        pageYield,
        inkVolume,
        environmental,
    } = productData
    const th = getThemeFactory('ProductsReducer', {})
    const theme: any = th()

    let energySymbol: string
    let energyLabel: string

    const lightclub: any[] = classifications?.filter(({ code }) => code === 'lightclub')
    const energy: any[] =
        lightclub?.[0]?.features?.filter(({ code }) => code === 'InkclubClassification/1.0/lightclub.energyclass') || []
    if (energy.length > 0) {
        try {
            energySymbol = energy[0].featureValues[0].value
            energySymbol = energySymbol.replace(/\+/g, '-plus')
            energyLabel = energy[0].name.replace('<b>', '').replace('</b>', '')
        } catch {
            energySymbol = undefined
        }
    }

    let priceValue: number = price.value
    let costPerPageValue: number = costPerPage?.value
    // Todo price inconsistency in the backend has to be removed
    let productDiscountPrice: number
    if (discountPrice && discountPrice !== null) {
        productDiscountPrice = discountPrice.value
    } else if (
        (typeof discountPrice === 'undefined' || discountPrice === null) &&
        basePrice !== null &&
        typeof basePrice !== 'undefined'
    ) {
        productDiscountPrice = price.value
        priceValue = basePrice.value
    }

    const product: Product = {
        name: name || title,
        code,
        title,
        url: sanitizeProductUrl(url),
        images: parseImageSubset(imagesSubset, `${themeResourcePath}/${theme.placeholderImageSrc}`),
        price: priceValue,
        costPerPage: costPerPageValue,
        discountedPrice: productDiscountPrice,
        currency: price.currencyIso,
        text: extractSummaryText(productData.summary),
        summary: extractSummaryText(productData.summary) || '',
        description,
        isTrialProduct: trialProduct,
        manufacturer,
        oneBuyOnlyProduct,
        oneBuyUserGroupId,
        discountPercentage,
        productInfoMessage,
        oneBuyOnlyPromotionPrice,
        virtualShelfPageId,
        productType,
        unitsPerPackage: unitsPerPackage !== null ? unitsPerPackage : 1,
        stockStatus: getProductStockStatus(stockStatus),
        printerModelCategoryCode,
        status: stockStatus?.availabilityEnum || status,
        productQuantityThreshold,
        subscriptionIsAvailable: subscribable,
        correspondsTo,
        productThumbnailUrl: thumbnailUrl,
        bracketPrices,
        specification,
        certifications: extractCertifications(classifications),
        classifications:
            classifications != null
                ? extractClassifications(classifications.filter((data) => data.code !== 'environmental'))
                : null,
        colorCode,
        color,
        manufacturerProductNumber: manufacturerProductNumber?.trim(), // Is sometimes " ", trim it
        breadcrumbs,
        energySymbol,
        energyLabel,
        isSnusProduct: productData.snusProduct,
        modelCategoryCode,
        // Meta-data
        metaTitle,
        metaDescription,
        keywords,
        pageYield,
        inkVolume,
        environmental,
    }

    const coffee = classifications?.find(({ code }) => code === 'coffee')
    if (coffee) {
        product.strength = exctractFeatureValue(coffee.features, 'InkclubClassification/1.0/coffee.strength')
        product.acidity = exctractFeatureValue(coffee.features, 'InkclubClassification/1.0/coffee.acidity')
        product.body = exctractFeatureValue(coffee.features, 'InkclubClassification/1.0/coffee.body')
        product.roasting = exctractFeatureValue(coffee.features, 'InkclubClassification/1.0/coffee.roastingvalue')
    }

    const placerHolderImageUrl: string = `${themeResourcePath}/${theme.conf.placeholderImageSrc}`

    if (!product.images) {
        // log.info('Weird product', product.code);
        product.images = {
            small: [{ url: placerHolderImageUrl }],
            medium: [{ url: placerHolderImageUrl }],
            large: [{ url: placerHolderImageUrl }],
            xlarge: [{ url: placerHolderImageUrl }],
            primary: {
                small: { url: placerHolderImageUrl },
                medium: { url: placerHolderImageUrl },
                large: { url: placerHolderImageUrl },
                xlarge: { url: placerHolderImageUrl },
            },
        }
    } else {
        product.images = {
            ...product.images,
            primary: {
                small: resolveImage(product.images.small, placerHolderImageUrl),
                medium: resolveImage(product.images.medium, placerHolderImageUrl),
                large: resolveImage(product.images.large, placerHolderImageUrl),
                xlarge: resolveImage(product.images.xlarge, placerHolderImageUrl),
            },
        }
    }

    // Adding a unique id to thumnails to avoid key warning in console
    product.images.small = product.images?.small?.map((smallImage: Image, i: number) => ({
        ...smallImage,
        key: `${code}_thumb_${i}`,
    }))

    return product
}

export const parseSlimProduct = (rawSlimProductData: any, themeResourcePath: string): SlimProduct => {
    const th = getThemeFactory('ProductsReducer', {})
    const theme: any = th()

    const {
        name,
        title,
        code,
        url,
        imgUrl,
        price,
        discountedPrice,
        currency,
        text,
        oneBuyOnlyProduct,
        oneBuyUserGroupId,
        infoMessage,
        oneBuyOnlyPromotionPrice,
        stockStatus,
        subscribable,
        subscriptionIsAvailable,
        bracketPrices,
        energySymbol,
        energyLabel,
        productQuantityThreshold,
        snusProduct: isSnusProduct,
        solrDocScore,
        correspondsTo,
        tags,
        imagesSubset,
    } = rawSlimProductData
    const slimProduct: SlimProduct = {
        name: title || name,
        code,
        url: sanitizeProductUrl(url),
        imgUrl: imgUrl !== undefined ? imgUrl : '',
        price,
        discountedPrice,
        currency,
        title: title || name,
        text: {
            sCopyDesktop: text?.scopyDesktop || '',
            sCopyMobile: text?.scopyMobile || '',
        },
        images: parseImageSubset(imagesSubset, `${themeResourcePath}/${theme.placeholderImageSrc}`),
        oneBuyOnlyProduct,
        oneBuyUserGroupId,
        infoMessage,
        oneBuyOnlyPromotionPrice,
        stockStatus,
        subscriptionIsAvailable: subscribable || subscriptionIsAvailable,
        bracketPrices,
        energySymbol,
        energyLabel,
        // Used by dinvitamin to know if hpem many items of a product you are allowed to buy
        productQuantityThreshold,
        isSnusProduct,
        correspondsTo,
        solrDocScore,
        tags,
    }
    if (correspondsTo && correspondsTo.match(STR_HAS_HIGHLIGHT_PATTERN_REGEX)) {
        slimProduct.text.sCopyDesktop = `${str('product.code.replaces')} ${correspondsTo}`
        slimProduct.text.sCopyMobile = `${str('product.code.replaces')} ${correspondsTo}`
    }
    return slimProduct
}

const exctractFeatureValue = (featuresArray: any[], featureCode: string, defaultValue: number = null): number => {
    const val: any = featuresArray.find(({ code }) => code === featureCode)
    if (val) {
        return val.featureValues[0].value as number
    }
    return defaultValue
}

const extractSummaryText = (summary: string): string => {
    if (!summary) {
        return ''
    }
    return filterTagsInText(summary, ['br'])
}

const extractClassifications = (classifications: any[]): string[][] => {
    const cfs: string[][] = []
    if (classifications && classifications.length > 0) {
        classifications.forEach(({ features }) => {
            if (features?.length > 0) {
                features.forEach(({ featureValues, name, featureUnit, code }) => {
                    if (featureValues?.length === 1) {
                        cfs.push([name, `${featureValues[0].value}${featureUnit ? ' ' + featureUnit.symbol : ''}`, code])
                    }
                    if (featureValues?.length > 1) {
                        const featureValuesList: any[] = []
                        featureValues.forEach((value) => featureValuesList.push(value.value))
                        cfs.push([name, `${featureValuesList}${featureUnit ? ' ' + featureUnit.symbol : ''}`, code])
                    }
                })
            }
        })
    }
    return cfs
}

const extractCertifications = (classifications: any[]): Certifications => {
    const certifications = { name: '', values: [] }
    const enviromentClassification = classifications?.find(({ code }) => code === 'environmental')

    if (enviromentClassification && enviromentClassification.features) {
        enviromentClassification.features.forEach((feature) => {
            if (feature.code === 'InkclubClassification/1.0/environmental.certification') {
                certifications.name = feature.name
                feature.featureValues.forEach((value) => {
                    let certificationOption
                    switch (value.value) {
                        case 'certi_AAA':
                        case 'Astma och Allergiförbundet':
                        case 'Astma- og allergiforeningen':
                        case 'Astma- och Allergiförbundet (Astma-Allergi)':
                        case 'Astma- ja allergiayhdistys':
                            certificationOption = { name: value.value, logo: 'astma_&_allergiforbundet_150x150.jpeg' }
                            break

                        case 'certi_SNF':
                        case 'Bra Miljöval':
                        case 'Godt miljøvalg':
                        case 'Miljøvenligt valg':
                        case 'Hyvä ympäristövalinta':
                            certificationOption = { name: value.value, logo: 'bra_miljoval_150x150.jpeg' }
                            break

                        case 'certi_BA':
                        case 'Blue Angel':
                            certificationOption = { name: value.value, logo: 'der_blauer_engel_150x150.jpeg' }
                            break

                        case 'certi_EUEL':
                        case 'EU Ecolabel':
                            certificationOption = { name: value.value, logo: 'eu-ecolabel_150x150.jpeg' }
                            break

                        case 'certi_EUP':
                        case 'Europalövet':
                        case 'Europaløvet':
                        case '"EU\'s grønne blad"':
                        case 'Eurolehti':
                            certificationOption = { name: value.value, logo: 'europa_lovet_council_150x150.jpeg' }
                            break

                        case 'certi_FT':
                        case 'Fairtrade':
                        case 'Reilu kauppa':
                            certificationOption = { name: value.value, logo: 'fairtrade_150x150.jpeg' }
                            break

                        case 'certi_FSC':
                        case 'Forest Stewardship Council':
                        case 'Skogforvaltningsrådet (FSC)':
                        case 'Forest Stewardship Council (FSC)':
                            certificationOption = { name: value.value, logo: 'forest_stewardship_council_150x150.jpeg' }
                            break

                        case 'certi_KM':
                        case 'Krav-märkt':
                        case 'Krav-merket':
                        case 'Krav-mærket':
                        case 'Krav-merkitty':
                            certificationOption = { name: value.value, logo: 'krav_150x150.jpeg' }
                            break

                        case 'certi_PEFC':
                        case 'Pan European Forest Certifikation':
                        case 'Pan europeisk skogsertifisering (PEFC)':
                        case 'Pan European Forest Certifikation (PEFC)':
                            certificationOption = { name: value.value, logo: 'pefc_150x150.jpeg' }
                            break

                        case 'certi_RA':
                        case 'Rainforest Alliance':
                        case 'Regnskogsalliansen':
                            certificationOption = { name: value.value, logo: 'rainforest_alliance_150x150.jpeg' }
                            break

                        case 'certi_SVN':
                        case 'Svanen':
                        case 'Joutsen':
                            certificationOption = { name: value.value, logo: 'svanen_150x150.jpeg' }
                            break

                        case 'certi_UTZ':
                        case 'UTZ Certified':
                        case 'UTZ-sertifisert':
                        case 'UTZ-certificeret':
                        case 'UTZ-sertifioitu':
                            certificationOption = { name: value.value, logo: 'utz_certified_150x150.jpeg' }
                            break
                        default:
                            break
                    }
                    if (certificationOption) {
                        certifications.values.push(certificationOption)
                    }
                })
            }
        })
    }
    return certifications
}

export const getProductStockStatus = (sourceStockStatus: any): StockStatus => {
    if (sourceStockStatus) {
        const useProductStockStatusCode: boolean =
            sourceStockStatus.productStockStatusCode === 'NewProductProlongedDeliveryTime_761' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
            sourceStockStatus.productStatusCode === 'ToBeDiscontinued_256' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_2' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToOneWeek_711' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_5' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToThreeWeeks_781' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_4' ||
            sourceStockStatus.productStockStatusCode === 'TemporarilyProlongedDeliveryTimeUpToTwoWeeks_721'

        const availability = typeof sourceStockStatus === 'object' ? sourceStockStatus.availabilityEnum : sourceStockStatus
        const stockStatusDescription = useProductStockStatusCode
            ? 'product.stockStatus.' + sourceStockStatus.productStockStatusCode
            : 'stockstatus.' + sourceStockStatus.availabilityEnum.toLowerCase()

        return {
            availability,
            stockStatusDescription,
            productStockStatusCode: sourceStockStatus.productStockStatusCode,
            productStatusCode: sourceStockStatus.productStatusCode,
        }
    }
}

const resolveImage = (imageArray: Image[], placeholderImageUrl: string) => {
    return imageArray?.[0] || { url: placeholderImageUrl }
}

const sanitizeProductUrl = (url: string): string => {
    if (url) {
        // if url contains a query string, remove it
        url = url.replace(/\?.*/, '')
        // decode the url
        url = decodeURIComponent(url)
        // if url contains a '+' sign, replace it with a 'plus'
        url = url.replace(/\+/g, 'plus')
    }
    return url
}

const filterTagsInText = (value: string, allowedTags: string[]): string => {
    let tagsRegex: string = ''
    allowedTags.forEach((tag: string) => {
        if (tag === 'br') {
            tagsRegex += '(?!br/)(?!br)'
        } else {
            tagsRegex = tagsRegex + '(?!' + tag + ')(?!/' + tag + ')'
        }
        return tagsRegex
    })
    const replaceString = new RegExp('<(' + tagsRegex + 's*/?)[^>]+>', 'g')
    return value.replace(replaceString, '')
}
