import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '../app/interfaces'
import { TrackingActionTypes } from '../../tracking/constants'
import { AppliedPromotion, CartStore } from '../cart/interfaces'
import { put, select } from 'redux-saga/effects'
import { NAME as cartReducerName } from '../cart/constants'

export const selectSessionConfig = (state): SessionConfig =>
    state?.reducers?.[sessionReducerName]?.sessionConfig || window['sessionConf']

export function* trackAppliedPromotions(
    newAppliedOrderPromotions: AppliedPromotion[] = [],
    newAppliedProductPromotions: AppliedPromotion[] = [],
) {
    const { appliedOrderPromotions, appliedProductPromotions }: CartStore = yield select(
        (state) => state?.reducers?.[cartReducerName],
    )
    const appliedOrderPromotionCodes: string[] = appliedOrderPromotions?.map(({ promotionData }) => promotionData.code)
    const appliedProductPromotionCodes: string[] = appliedProductPromotions?.map(({ promotionData }) => promotionData.code)
    const newAppliedOrderPromotionCodes: string[] = newAppliedOrderPromotions.map(({ promotionData }) => promotionData.code) || []
    const newAppliedProductPromotionCodes: string[] =
        newAppliedProductPromotions.map(({ promotionData }) => promotionData.code) || []

    const appliedOrderPromotionsChanged: boolean =
        newAppliedOrderPromotionCodes.length !== appliedOrderPromotionCodes.length ||
        newAppliedOrderPromotionCodes.filter((promotionCode: string) => !appliedOrderPromotionCodes.includes(promotionCode))
            .length > 0
    const appliedProductPromotionsChanged: boolean =
        newAppliedProductPromotionCodes.length !== appliedProductPromotionCodes.length ||
        newAppliedOrderPromotionCodes.filter((promotionCode: string) => !appliedProductPromotionCodes.includes(promotionCode))
            .length > 0

    if (appliedOrderPromotionsChanged || appliedProductPromotionsChanged) {
        yield put({
            type: TrackingActionTypes.PROMOTIONS_UPDATED,
            payload: {
                promotionCodes: [...newAppliedOrderPromotionCodes, ...newAppliedProductPromotionCodes],
            },
        })
    }
}
