const themeGen = theme => { // eslint-disable-line
  const res = {
    sectionDivider: true,
    showUpsellSection: true,
    // If you want to add a new column here you also need to add it to the list of valid columns in productsSvc
    columns: [
      {
        columnId: 'productImage',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productCode',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productCorrespondsTo',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productName',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productUsage',
        size: 1,
        classes: '',
      },
      {
        columnId: 'pageCapacity',
        size: 1,
        classes: '',
      },
      {
        columnId: 'productManufacturer',
        size: 1,
        classes: '',
      },
      {
        columnId: 'environmental',
        size: 1,
        classes: '',
      },
      {
        columnId: 'stock',
        size: 1,
        classes: '',
      },
      {
        columnId: 'price',
        size: 1,
        classes: 'text-center priceTableData',
      },
      {
        columnId: 'addToCartAndSubscription',
        size: 1,
        classes: '',
      },
    ],
    productCodeColor: theme.col.pale.alt,
    conf: {
      showOneKronaSticker: true,
    },
  };
  return res;
};

export default themeGen;
