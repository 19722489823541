import React, { Fragment } from 'react';
import Icon, { Icons } from '@ui-elem/Icon/Icon';
import styled from 'styled-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import sortedUniqBy from 'lodash/sortedUniqBy';
import find from 'lodash/find';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import scrollToComponent from 'react-scroll-to-component';
import { setupInitialState, mapItems, setupMutations, areStatePropsEqual } from 'core/util/module-utils';
import net from 'score/networkSvc';
import logger from 'score/logSvc';
import { str } from 'seco/localeSvc';
import { getThemeFactory } from '@ic-theme';
import __DEV__ from '__DEV__';
import AnimatedList from 'ui/nursery/Animations/AnimatedList';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

// Setup module multi/single instance name etc
const multipleInstances = false;

const name = 'GlobalMessages';

const theme = getThemeFactory(name, {});

// Mudules data, this is the initial data for an instance
const initialState = {
  messages: [
    // {
    //   id: 1,
    //   title: 'Välkomna till minespresso',
    //   message: 'Detta är ett exempel på ett meddelande',
    //   level: 'error',
    // },
  ],
};
/**
 * Used by the tests to set the flag to true
 */

const log = logger.getLogger(name); // eslint-disable-line
const conf = { multipleInstances, name, initialState };

const Message = (p) => (
  <Fragment key={p.id}>
    <div className="px-0 py-1 m-0">
      <div
        id={'message_' + p.id}
        className={classnames('flex round-box items-center justify-between cursor-pointer mb-4', 'col-' + p.level, 'round-box')}
        key={p.id}
        onClick={() => {
          p.removeMessage(p.id);
        }}
        ref={(ref) => {
          scrollToPos = ref;
        }}
        data-testid={'message_' + p.id}
      >
        <div className={'flex p-2'}>
          <div className="title">{p.title}</div>
          <div className="msg ic-padding-l">{p.message}</div>
        </div>
        <Icon icon={Icons.Times} className={'pr-1'} />
      </div>
    </div>
  </Fragment>
);

const TechincalInfo = styled.div`
  &&& {
    color: #666666;
    padding-top: ${(p) => p.theme.space}rem;
  }
`;

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  return instance;
};

// ################# ACTIONS  #################

// Distingush
const timeouts = {}; // eslint-disable-line

export const actions = (dispatch) => {
  const self = {
    addMessage: (title, message, level, _id, valid, statusCode) => {
      let id = _id;
      if (!id) {
        id = new Date().getTime();
      }
      dispatch({
        type: 'GLOBAL_MESSAGE_ADD',
        id,
        title,
        message,
        level,
        valid,
        gaVal: id,
        statusCode,
      });

      if (valid) {
        // Remove earlier timeouts so that it will stay of the time requested
        clearTimeout(timeouts[id]);
        timeouts[id] = setTimeout(() => {
          dispatch({
            type: 'GLOBAL_MESSAGE_REMOVE',
            id,
          });
        }, valid * 1000);
      }
    },
    addRestCallFailed: (url, method, err) => {
      if (err.status === 200 || typeof err.state === 'undefined') {
        return;
      }
      let id = url;
      if (!id) {
        id = new Date().getTime();
      }
      self.addMessage(
        str('phrase.network.error.title'),
        (
          <div>
            {str('phrase.network.error.title')} <Icon icon={Icons.SadTear} className={'pr-1'} /> <br />
            {__DEV__ ? (
              <TechincalInfo theme={theme()}>
                Techincal information: <br />
                Url: {url} <br />
                Method: {method}
                <br />
                Result code: {err.status}
                <br />
                Result text: {err.statusText}
                <br />
              </TechincalInfo>
            ) : null}
          </div>
        ) || 'Try again later',
        'error',
        id,
        100000,
        err.status
      );
      log.error(
        str('phrase.network.error.title'),
        'Techincal information: ',
        'Url:',
        url,
        'Method:',
        method,
        'Result code:',
        err.status,
        'Result text:',
        err.statusText
      );
    },
    removeMessage: (id) =>
      dispatch({
        type: 'GLOBAL_MESSAGE_REMOVE',
        id,
      }),
    removeAllMessages: (id) =>
      dispatch({
        type: 'GLOBAL_MESSAGE_REMOVE_ALL',
        id,
        noAnalytics: true,
      }),
    fetchGlobalMessageFromStorage: () => {
      /*
      const logoutMsgStr = browserSvc.sessionGet('logout_msg');
      if (logoutMsgStr) {
        const logoutMsg = JSON.parse(logoutMsgStr);
        logoutMsg.type = 'GLOBAL_MESSAGE_ADD';
        browserSvc.sessionRemove('logout_msg');

        self.addMessage(logoutMsg.title, logoutMsg.message, logoutMsg.level, logoutMsg.id, 10);
      }
      */
    },
    fetchGlobalMessageFromServer: async (msgKey) => {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      const url = sessionConfig.urlPrefix + '/rest/v1/message/global/' + msgKey + '/';
      try {
        const result = await net.get(url);

        let title = str(result.content.title);
        // Ignore an undefiend title
        if (title === result.content.title) {
          title = '';
        }

        if (result.content) {
          self.addMessage(title, str(result.content.message), result.content.type, msgKey, 5);
        }
      } catch (err) {
        self.addRestCallFailed(url, 'GET', err);
      }
    },
    fetchAllGlobalMessageFromServer: async () => {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      const url = sessionConfig.urlPrefix + '/rest/v1/message/global/all';
      try {
        const result = await net.get(url);
        Object.entries(result.content).forEach((e) => {
          const gMessage = e[1];
          let title = str(gMessage.title);
          // Ignore an undefiend title
          if (title === gMessage.title) {
            title = '';
          }
          if (gMessage) {
            self.addMessage(title, str(gMessage.message), gMessage.type, e[0], 60);
          }
        });
      } catch (err) {
        self.addRestCallFailed(url, 'GET', err);
      }
    },
    // Commented out as this should not be an issue when we no longer read the whole state
    // from sesion storage

    // removeNetworkErrorMessagesFromStorage: async () => {
    //   try {
    //     dispatch(async (_disp) => {
    //       _disp({ type: 'NETWORKERROR_MESSAGE_REMOVE_STORAGE', noAnalytics: true });
    //     });
    //     setTimeout(() => dispatch({ type: 'MARK_READY_FOR_RENDERING', noAnalytics: true }), 500);
    //   } catch (e) {
    //     log.warn('Error handling netowkr storage', e);
    //   }
    // },
  };
  return self;
};

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  GLOBAL_MESSAGE_ADD: (state, action, _ownProps) => {
    const existingMessage = find(state.messages, { id: action.id });

    if (!existingMessage) {
      state.messages.push({
        id: action.id,
        title: action.title,
        message: action.message,
        level: action.level,
        statusCode: action.statusCode,
      });
    }
    state.messages = sortedUniqBy(state.messages, 'id');
    // Yield so that the rendering of the error gets to go first
    // if (action.level === 'error') {
    setTimeout(() => {
      scrollToComponent(scrollToPos);
    }, 1);
  },
  GLOBAL_MESSAGE_REMOVE: (state, action) => {
    remove(state.messages, { id: action.id });
  },
  PAGE_VIEW: (state, _action) => {
    // Removing all messages except for the logout message whien switching page
    state.messages = filter(state.messages, (msg) => msg.id === 'logout.success');
  },
  GLOBAL_MESSAGE_REMOVE_ALL: (state, _action) => {
    state.messages = [];
  },
  NETWORKERROR_MESSAGE_REMOVE_STORAGE: (state, _action) => {
    state.messages = state.messages.filter((m) => m.statusCode === 200 || m.statusCode === undefined);
  },
  // MARK_READY_FOR_RENDERING: (state, action) => {
  //   readyForRendering = true;
  // },
};
/* eslint-enable */

// ################# MODULE SETUP DONT TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  mutations: setupMutations(mutations, conf) // eslint-disable-line
};
// ################# RENDER  #################
let scrollToPos;
class GlobalMessages extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.fetchGlobalMessageFromStorage();
    }, 0);
  }

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    return (
      <>
        <AnimatedList key="global-messages-animates-list">
          {this.props.messages.map((message) => (
            <Message removeMessage={this.props.removeMessage} {...message} />
          ))}
        </AnimatedList>
      </>
    );
  }
}
GlobalMessages.contextTypes = { store: PropTypes.object };
const ExportedGlobalMessages = connect(getters, actions, undefined, {
  areStatePropsEqual,
})(GlobalMessages);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default ExportedGlobalMessages;
