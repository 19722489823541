import { resetNavigation } from '@spa-core/store/navigation/actions';
import { NAME as navigationReducerName, NavigationType } from '@spa-core/store/navigation/constants';
import { ActionTypes as SessionActionTypes } from '@spa-core/store/app/constants';
import CatchAllRoutes from '@spa-ec/components/CatchAllRoutes/CatchAllRoutes';
import 'core/util/marketing';
import { navigation } from 'eco/AppUtil/AppUtilMod.jsx';
import { actions as globalMessagesActions } from 'eco/GlobalMessages/GlobalMessagesMod.jsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import browserSvc from 'score/browserSvc';
import { init as initNetworkService } from 'score/networkSvc';
import { svcData as navData, actions as navigationSvcActions } from 'seco/navigationSvc/navigationSvcMod';
import setupRouting from 'seco/navigationSvc/setupRouting.jsx';
import { updateCMSTicketIdInSessionStorage } from './util/smartedit';

let getComponentByType;
export const inject = (_c, _getComponentByType) => {
  getComponentByType = _getComponentByType;
};

// ################# GETTERS  #################

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => ({
  handleSmarteditNavigation: () =>
    dispatch(async () => {
      const smarteditURL = browserSvc.localStorageGet('smarteditHistory');
      const smarteditPreviewUrl = browserSvc.localStorageGet('smarteditPreviewUrl');
      if (
        smarteditPreviewUrl !== null &&
        smarteditPreviewUrl !== undefined &&
        smarteditURL !== undefined &&
        smarteditURL !== null
      ) {
        navigation.navigateTo(smarteditURL);
        let pkPart1 = Math.random().toFixed(14).split('.')[1];
        let pkPart2 = Math.random().toFixed(16).split('.')[1];

        const event = {
          pk: '' + pkPart1 + '.' + pkPart2 + '',
          gatewayId: 'waitDialogService',
          eventId: 'hideWaitModal',
          data: { arguments: [] },
          triggeredBy: 'SPA-Frontend',
        };
        parent.postMessage(event, smarteditPreviewUrl);
      }
    }),
  checkForAdvancedEditMode: () => {
    if (window.addEventListener) {
      window.addEventListener('message', handleMessage);
    } else {
      window.attachEvent('onmessage', handleMessage);
    }

    function handleMessage(event) {
      if (event.data.gatewayId === 'CROSS_FRAME_EVENT' && event.data.eventId === 'advancedEditActive') {
        let advnceEditFlag = false;
        if (event.data.data === 'se.cms.perspective.advanced') {
          advnceEditFlag = true;
        }
        dispatch({ type: 'CHECK_IF_SMARTEDIT_ADVANCED_MODE', advnceEditFlag });
      }
    }
  },
  handleDeviceWidth: (currentDeviceType, size) =>
    dispatch(async (_disp) => {
      function mobile(x) {
        if (x.matches && currentDeviceType !== 'mobile') {
          _disp({ type: 'GLOBAL_EVENT_DEVICE_TYPE', deviceType: 'mobile' });
        }
      }
      function tablet(x) {
        if (x.matches && currentDeviceType !== 'tablet') {
          _disp({ type: 'GLOBAL_EVENT_DEVICE_TYPE', deviceType: 'tablet' });
        }
      }
      function desktop(x) {
        if (x.matches && currentDeviceType !== 'desktop') {
          _disp({ type: 'GLOBAL_EVENT_DEVICE_TYPE', deviceType: 'desktop' });
        }
      }
      let desktopCheck = window.matchMedia(`(min-width: ${size.md}px)`);
      desktop(desktopCheck);
      let tabletCheck = window.matchMedia(`(min-width: ${size.xs}px) and (max-width: ${size.md}px)`);
      tablet(tabletCheck);
      let mobileCheck = window.matchMedia(`(max-width: ${size.xs}px)`);
      mobile(mobileCheck);

      mobileCheck.addEventListener('change', mobile);
      tabletCheck.addEventListener('change', tablet);
      desktopCheck.addEventListener('change', desktop);
    }),
  ...globalMessagesActions(dispatch, ownProps),
  ...navigationSvcActions(dispatch, ownProps),
  // ...restoreCartQueryActions(dispatch, ownProps),
});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
export const mutations = {
  [SessionActionTypes.SET_CONFIG]: (state, action) => {
    const { payload } = action;
    state.isLoggedIn = payload?.sessionConfig.userLoggedIn;
    state.campaignPageId = payload?.sessionConfig.campaignPageId;
  },
  APP_SAVE_COOKIES: (state, action) => {
    state.ID2 = action.ID2;
    state.JSESSIONID = action.JSESSIONID;
  },
  LEGACY_CAMPAIGN_ACTIVATED: (state, action) => {
    state.t = action.t;
  },
  GLOBAL_EVENT_DEVICE_TYPE: (state, action) => {
    state.deviceType = action.deviceType;
  },
  CHECK_IF_SMARTEDIT_ADVANCED_MODE: (state, action) => {
    state.advnceEditFlag = action.advnceEditFlag;
  },
};

export const mountFun = (fetchSitemap, props) => {
  initNetworkService(props.resetSession);
  fetchSitemap();
  // subscribe2Init(props.handleAbondenedCartData);
};

const _AppRouting = (p) => {
  const ContentPage = getComponentByType('CONTENT');
  updateCMSTicketIdInSessionStorage();
  // check if location.pathname contains "/preview-content"

  // Logic to redirect to page based on redux state
  const history = useHistory();
  const reducerDispatch = useDispatch();
  const navigationStore = ({ reducers }) => reducers[navigationReducerName];

  const selector = createSelector([navigationStore], (navStore) => ({
    doRedirect: navStore.doNavigate && navStore.navigationType === NavigationType.REDIRECT && navStore.url,
    changeLocationTo: navStore.doNavigate && navStore.navigationType === NavigationType.NAVIGATE && navStore.url,
    gotoUrl: navStore.url,
  }));

  const { doRedirect, gotoUrl, changeLocationTo } = useSelector(selector);

  useEffect(() => {
    if (doRedirect && gotoUrl) {
      reducerDispatch(resetNavigation());
      history.push(gotoUrl);
    }
  }, [history, gotoUrl, reducerDispatch, doRedirect]);

  useEffect(() => {
    if (changeLocationTo && gotoUrl) {
      window.location = gotoUrl;
    }
  }, [changeLocationTo, gotoUrl]);

  const isPreviewContent =
    location.pathname.includes('/preview-content') ||
    location.pathname.includes('/;jsessionid=') ||
    (location.search && location.search.includes('cmsTicketId'));
  let previewPageProxy;
  if (isPreviewContent && location.search && (location.search.includes('uid') || location.search.includes('cmsTicketId'))) {
    let previewPageUid;
    // extract preview page uid from location.search string as it is in the format "?uid=1234&abc=true"
    if (location.search.includes('uid')) {
      previewPageUid = location.search.split('=')[1].split('&')[0];
    }

    let previewPage =
      previewPageUid !== undefined && navData && navData.routes
        ? navData.routes.filter((route) => route.pageId === previewPageUid)
        : null;

    // Especial case for previewing a homepage
    if (location.pathname.includes('/;jsessionid=') && (!previewPage || (previewPage && previewPage.length === 0))) {
      previewPage = [{ url: '/' }];
    }
    if (previewPage && previewPage.length > 0) {
      // if preview page is present in the sitemap, then pass previewPage url as location.pathname to the ContentPage component
      // let previewPageProxy be a deepclone of location object
      previewPageProxy = JSON.parse(JSON.stringify(location));
      previewPageProxy.pathname = previewPage[0].url;
    }
  }

  return previewPageProxy === undefined ? (
    <Route
      render={({ location }) => (
        <div key={location.pathname}>
          <Switch location={location}>
            {setupRouting(
              navData.orderedRoutesArr,
              p.isLoggedIn,
              getComponentByType,
              p.isMobile,
              p.campaignPageId,
              p.campaignNumber
            )}
            {navData.routes && Object.keys(navData.routes).length > 0 ? (
              <Route
                path="*"
                render={(props) => (
                  <>
                    {p.campaignPageId ? (
                      <ContentPage {...props} pageId={p.campaignPageId} isLoggedIn={props.isLoggedIn} />
                    ) : (
                      <CatchAllRoutes />
                    )}
                  </>
                )}
              />
            ) : null}
          </Switch>
        </div>
      )}
    />
  ) : (
    <Route
      render={({ location }) => (
        <div
          key={
            location.pathname.includes('/preview-content') || location.pathname.includes('/;jsessionid=')
              ? previewPageProxy.pathname
              : location.pathname
          }
        >
          <Switch
            location={
              location.pathname.includes('/preview-content') || location.pathname.includes('/;jsessionid=')
                ? previewPageProxy
                : location
            }
          >
            {setupRouting(
              navData.orderedRoutesArr,
              p.isLoggedIn,
              getComponentByType,
              p.isMobile,
              p.campaignPageId,
              p.campaignNumber
            )}
            {navData.routes && Object.keys(navData.routes).length > 0 ? (
              <Route
                path="*"
                // eslint-disable-next-line sonarjs/no-identical-functions
                render={(props) => (
                  <>
                    {p.campaignPageId ? (
                      <ContentPage {...props} pageId={p.campaignPageId} isLoggedIn={props.isLoggedIn} />
                    ) : (
                      <CatchAllRoutes />
                    )}
                  </>
                )}
              />
            ) : null}
          </Switch>
        </div>
      )}
    />
  );
};
_AppRouting.propTypes = { isLoggedIn: PropTypes.bool };

export const AppRouting = _AppRouting;
