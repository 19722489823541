import React, { MouseEventHandler } from 'react'
import { getDammsugarpasarIcon, getDefaultIcon, getLamportillalltIcon } from './utils'
import { Icons } from './constants'
import classNames from 'classnames'
import { IconStyles } from './interfaces/IconStyles'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'

enum Styles {
    ANIMATE_ROTATE_90 = 'animate_rotate_90',
    ANIMATE_ROTATE_180 = 'animate_rotate_180',
    ANIMATE_ROTATE_0 = 'animate_rotate_0',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    icon: Icons
    className?: string
    onClick?: MouseEventHandler
    size?: number
    rotate90?: boolean
    rotate180?: boolean
}

const Icon: React.FC<Props> = ({ icon, className, onClick, size, rotate90, rotate180 }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const styles: IconStyles = {
        height: 16,
        width: 16,
    }
    if (size) {
        styles.height = size
        styles.width = size
    }

    const site: string = sessionConfig?.siteName?.toUpperCase() || ''
    let displayIcon: React.ReactNode

    if (site === 'DAMMSUGARPASAR') {
        displayIcon = getDammsugarpasarIcon(icon, styles)
    } else if (site === 'LAMPORTILLALLT') {
        displayIcon = getLamportillalltIcon(icon, styles)
    } else {
        displayIcon = getDefaultIcon(icon, styles)
    }

    return (
        <div
            onClick={onClick}
            className={classNames(
                className,
                rotate90 && !rotate180 ? Styles.ANIMATE_ROTATE_90 : '',
                rotate180 && !rotate90 ? Styles.ANIMATE_ROTATE_180 : '',
                !rotate90 && !rotate180 ? Styles.ANIMATE_ROTATE_0 : '',
            )}
        >
            {displayIcon}
        </div>
    )
}

export { Icons } from './constants'
export default Icon
