import browserSvc from '@spa-core-js/services/browserSvc'
import logger from '@spa-core-js/services/logSvc'
import net from '@spa-core-js/services/networkSvc'
import * as EmailValidator from 'email-validator'
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import { SessionConfig, Promotions } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { ActionTypes, NAME as productsReducerName, ProductLoadingStatus } from './constants'
import { FetchProductByCodePayload, Product, ProductLoadingStatuses, ProductsStore, TestFreakReview } from './interfaces'
import { ActionTypes as AppActionTypes } from '../app/constants'

import { parseProduct, parseSlimProduct } from './utils'

const log = logger.getLogger('Products')

export function* fetchProductByCode({ payload }: any) {
    const { productCode, forceRefetch = false }: FetchProductByCodePayload = payload

    const loadingStatuses: ProductLoadingStatuses = yield select(
        (state) => state?.reducers?.[productsReducerName]?.loadingStatuses,
    )

    if (loadingStatuses?.[productCode] === ProductLoadingStatus.FETCHING) {
        return
    }

    if (!forceRefetch) {
        const productsStore: ProductsStore = yield select((state) => state?.reducers?.[productsReducerName])
        if (productsStore?.products?.[productCode] || productsStore?.slimProducts?.[productCode]) {
            return
        }
    }

    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    yield put({
        type: ActionTypes.SET_FETCHING_PRODUCTS,
        payload: {
            fetchingProducts: true,
        },
    })

    yield put({
        type: ActionTypes.UPDATE_PRODUCT_LOADING_STATUS,
        payload: {
            loadingStatus: ProductLoadingStatus.FETCHING,
            productCode,
        },
    })
    const url: string = sessionConfig.urlPrefix + `/rest/v2/products/${productCode}`
    try {
        const result = yield call(() => net.get(url, { cache: 'none' }))
        const product: Product = parseProduct(result, sessionConfig.themeResourcePath)
        yield put({
            type: ActionTypes.FETCHED_PRODUCTS,
            payload: {
                products: [product],
            },
        })
        yield put({
            type: ActionTypes.UPDATE_PRODUCT_LOADING_STATUS,
            payload: {
                loadingStatus: ProductLoadingStatus.FETCHED,
                productCode,
            },
        })
    } catch (err) {
        log.info('Error in fetching single prod data ', err)
        yield put({
            type: ActionTypes.UPDATE_PRODUCT_LOADING_STATUS,
            payload: {
                loadingStatus: ProductLoadingStatus.NOT_FOUND,
                productCode,
            },
        })
    }
    yield put({
        type: ActionTypes.SET_FETCHING_PRODUCTS,
        payload: {
            fetchingProducts: false,
        },
    })
}

export function* fetchProductRelations({ payload }: any) {
    const { productCode, callback } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const productRelations = yield call(() =>
            net.get(sessionConfig.urlPrefix + '/rest/v1/products/modelcategories/' + productCode),
        )
        yield put({
            type: ActionTypes.FETCHED_PRODUCT_RELATIONS,
            productRelations,
        })
        if (callback) callback(productRelations)
    } catch (err) {
        log.error('Error in loading relations for product code: ' + productCode, err)
    }
}

const selectProducts = (state) => ({
    products: state.reducers[productsReducerName].products,
    slimProducts: state.reducers[productsReducerName].slimProducts,
})

export function* fetchProductsInBatch({ payload }: any) {
    const { productCodes, slimApi } = payload
    if (!productCodes.length) {
        return
    }

    yield put({
        type: ActionTypes.SET_FETCHING_PRODUCTS,
        payload: {
            fetchingProducts: true,
        },
    })

    const { products, slimProducts } = yield select(selectProducts)
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    const loadedProducts = slimApi ? slimProducts : products
    const productCodesToLoad = productCodes.filter((code) => !loadedProducts[code])

    while (productCodesToLoad.length > 0) {
        // Making call for the product codes in chunks of 50, as the backend API
        // supports returns maximum 50 products in one call for security purpose
        const productCodesBatch = productCodesToLoad.splice(0, 50)
        const productCodesParam = productCodesBatch.join(',')
        try {
            const result = yield call(() =>
                net.get(
                    `${sessionConfig.urlPrefix}/rest/v2/batch/products?codes=${productCodesParam}${slimApi ? '&slim=true' : ''}`,
                ),
            )
            const products = Object.keys(result)
                .filter((key) => key && result[key])
                .map((key) =>
                    slimApi
                        ? parseSlimProduct(result[key], sessionConfig.themeResourcePath)
                        : parseProduct(result[key], sessionConfig.themeResourcePath),
                )
            if (slimApi) {
                yield put({
                    type: ActionTypes.FETCHED_SLIM_PRODUCTS,
                    payload: {
                        slimProducts: products,
                    },
                })
            } else {
                yield put({
                    type: ActionTypes.FETCHED_PRODUCTS,
                    payload: {
                        products,
                    },
                })
            }
        } catch (err) {
            log.error('Failed to load products data: ' + productCodesParam, err)
        }
    }

    yield put({
        type: ActionTypes.SET_FETCHING_PRODUCTS,
        payload: {
            fetchingProducts: false,
        },
    })
}

export function* fetchReplacementProducts({ payload }: any) {
    const { productCode, quantity } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const result = yield call(() =>
            net.get(`${sessionConfig.urlPrefix}/rest/v1/products/replacementproduct/${productCode}?quantity=${quantity}`),
        )
        yield put({
            type: ActionTypes.FETCHED_REPLACEMENT_PRODUCTS,
            payload: {
                productCode,
                result,
            },
        })
    } catch (err) {
        log.error('fetchReplacementProducts error', err)
    }
}

export function* fetchSubscriptionOptions({ payload }: any) {
    const { productCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const result = yield call(() =>
            net.get(
                `${sessionConfig.urlPrefix}/rest/v1/products/subscriptionoptions/${productCode}?source=loadSubscriptionOptions`,
            ),
        )
        yield put({
            type: ActionTypes.FETCHED_SUBSCRIPTION_OPTIONS,
            payload: {
                productCode,
                subscriptionOptions: result,
            },
        })
    } catch (err) {
        log.error('Error in loading subscription options for product code: ' + productCode, err)
    }
}

export function* registerForStockNotification({ payload }: any) {
    const { email, productCode, modelCategoryCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    let validationErrors = false
    let error = ''
    if (email === '') {
        validationErrors = true
        error = 'checkout.form.email.invalid'
    } else if (!EmailValidator.validate(email)) {
        validationErrors = true
        error = 'invalid.email.text'
    }
    if (validationErrors) {
        yield put({
            type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR,
            payload: {
                error,
            },
        })
        return
    }

    const postData: string = `customerEmail=${email}&productCode=${productCode}${
        modelCategoryCode ? '&modelCode=' + modelCategoryCode : ''
    }`

    try {
        yield call(() => net.post(`${sessionConfig.urlPrefix}/rest/v1/products/stockstatus/notify/add`, postData))
    } catch (e) {
        let error = 'form.global.error'
        if (e.status === 400) {
            error = 'invalid.email.text'
        }
        yield put({
            type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR,
            payload: {
                error,
            },
        })
        browserSvc.localStorageSet(`stock_monitor_${productCode}`, false)
        browserSvc.localStorageSet('stock_monitor_email', email)
        return
    }

    browserSvc.localStorageSet(`stock_monitor_${productCode}`, true)
    browserSvc.localStorageSet('stock_monitor_email', email)

    yield put({
        type: ActionTypes.SET_STOCK_MONITORED,
        payload: {
            productCode,
            monitored: true,
        },
    })
    yield put({
        type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR,
        payload: {
            error: '',
        },
    })
}

export function* unregisterForStockNotification({ payload }: any) {
    const { productCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const alreadyMonitored: string = browserSvc.localStorageGet(`stock_monitor_${productCode}`)
    const email: string = browserSvc.localStorageGet('stock_monitor_email')
    if (alreadyMonitored && email) {
        const postData: string = `customerEmail=${email}&productCode=${productCode}`
        try {
            yield call(() => net.post(`${sessionConfig.urlPrefix}/rest/v1/products/stockstatus/notify/remove`, postData))
            browserSvc.localStorageSet(`stock_monitor_${productCode}`, false)
            browserSvc.localStorageRemove('stock_monitor_email')
            yield put({
                type: ActionTypes.SET_STOCK_MONITORED,
                payload: {
                    productCode,
                    monitored: false,
                },
            })
            yield put({
                type: ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR,
                payload: {
                    error: '',
                },
            })
        } catch (_) {
            browserSvc.localStorageRemove(`stock_monitor_${productCode}`)
            browserSvc.localStorageRemove('stock_monitor_email')
            yield put({
                type: ActionTypes.SET_STOCK_MONITORED,
                payload: {
                    productCode,
                    monitored: false,
                },
            })
        }
    }
}

export function* fetchCrossSellProducts() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const productCodes: string[] = yield call(() => net.get(`${sessionConfig.urlPrefix}/rest/v2/cart/upsell?maxCount=5`))
        if (productCodes?.length > 0) {
            yield fetchProductsInBatch({
                payload: {
                    productCodes,
                    slimApi: true,
                },
            })
        }
        yield put({
            type: ActionTypes.FETCHED_CROSS_SELL_PRODUCT_CODES,
            payload: {
                productCodes,
            },
        })
    } catch (err) {
        log.error('Error in fetching the cross sell products', err)
    }
}

export function* fetchPromotions() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    try {
        const promotions: Promotions = yield call(() => net.get(`${sessionConfig.urlPrefix}/rest/v1/promotionData`))
        if (promotions) {
            yield put({
                type: ActionTypes.FETCHED_PROMOTIONS,
                payload: {
                    promotions,
                },
            })
        }
    } catch (err) {
        log.error('Error in fetching promotions')
    }
}

export function* fetchProductReviewByCode({ payload }: any) {
    try {
        const { productCode } = payload
        /**
         * testFreaksClientId is injected through third party scripts fetched by the init request
         */
        const testFreaksClientId: string = window['testFreaksClientId']
        if (!testFreaksClientId) {
            /**
             * If testFreaksClientId is missing, disable testfreaks
             */
            const sessionConfig: SessionConfig = yield select(selectSessionConfig)
            sessionConfig.enableTestFreaks = false
            yield put({
                type: AppActionTypes.SET_CONFIG,
                payload: { sessionConfig },
            })
            log.warn('TestFreaks - testFreaksClientId missing')
        } else {
            const testFreaksUrlContext: string = `https://js.testfreaks.com/onpage/${testFreaksClientId}/reviews.json`
            const callBackContext: string = '&callback='
            const productParamContext: string = productCode === 'demo' ? '?demo_product=' : `?key=${productCode}`
            const url: string = testFreaksUrlContext + productParamContext + callBackContext
            const testFreaksReview: TestFreakReview = yield call(() => net.get(url))
            yield put({
                type: ActionTypes.FETCHED_PRODUCT_REVIEW_BY_CODE,
                payload: {
                    productCode,
                    testFreaksReview: testFreaksReview || {
                        reviewCount: undefined,
                        averageRating: undefined,
                    },
                },
            })
        }
    } catch (err) {
        log.error('Error in fetching testfreaks')
    }
}

export function* setComparePopupStatus(popupStatusValue) {
    const popupValue = popupStatusValue.payload.popupStatusValue
    yield put({
        type: ActionTypes.SET_COMPARE_POPUP_FLAG,
        payload: {
            popupStatusValue: popupValue,
        },
    })
}

export const watchers = [
    takeLatest(ActionTypes.FETCH_PROMOTIONS, fetchPromotions),
    takeEvery(ActionTypes.FETCH_PRODUCT_BY_CODE, fetchProductByCode),
    takeLatest(ActionTypes.FETCH_PRODUCT_RELATIONS, fetchProductRelations),
    takeEvery(ActionTypes.FETCH_PRODUCTS_IN_BATCH, fetchProductsInBatch),
    takeLatest(ActionTypes.FETCH_REPLACEMENT_PRODUCTS, fetchReplacementProducts),
    takeLatest(ActionTypes.FETCH_SUBSCRIPTION_OPTIONS, fetchSubscriptionOptions),
    takeLatest(ActionTypes.FETCH_CROSS_SELL_PRODUCT_CODES, fetchCrossSellProducts),
    takeLatest(ActionTypes.REGISTER_FOR_STOCK_NOTIFICATION, registerForStockNotification),
    takeLatest(ActionTypes.UNREGISTER_FOR_STOCK_NOTIFICATION, unregisterForStockNotification),
    takeLeading(ActionTypes.FETCH_PRODUCT_REVIEW_BY_CODE, fetchProductReviewByCode),
    takeLatest(ActionTypes.OPEN_COMPARE_POPUP, setComparePopupStatus),
    takeLatest(ActionTypes.CLOSE_COMPARE_POPUP, setComparePopupStatus),
]
