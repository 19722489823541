import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { newStore } from '@spa-core/redux/store';
import { Provider } from 'react-redux';
import logger from 'score/logSvc';
import localeSvc from 'seco/localeSvc';
import siteLocaleSvc from 'seco/siteLocaleSvc/siteLocaleSvc';
import { handleInitialStyling, isPuppeteer, handleSSRFallbacks } from 'core/util/extractStyles';
import pkg from '~/package.json';
import { ActionTypes as SessionActionTypes } from '@spa-core/store/app/constants';
import { getReducer } from 'core/redux/reducer';

const log = logger.getLogger('kickstart'); // eslint-disable-line
log.info('Starting iPiccolo eCommerce Butiken ' + pkg.version);
log.info(...log.stamp(), 'Build: ' + BUILD_NUMBER); // eslint-disable-line no-undef
/**
 * Function that detetcts and ipad based on the javascript navigator object. The ironical name of
 * the function indicates the outrage that we need to fiddle with this at all... or is it sarcastic?
 */
const isStealthIpad = () => {
  return (
    navigator.maxTouchPoints && navigator.maxTouchPoints > 1 && navigator.platform === 'MacIntel' && window.screen.width <= 970
  );
};

// Utility function that can be used by thrid-party scripts
// no specific use by the client itself
const executionDb = {};
window.runOnce = (id, fun) => {
  if (!executionDb[id]) {
    executionDb[id] = true;
    fun();
  }
};

window.runOnce('log', () => {
  log.trace('Only once');
});

// This statement imports the application wich in turn starts the application bootstrapping,
// the applicatin will provide the first reducer which is required ibn order to create the store
// in the upcoming lines

// Find the root element of the application in which we pu the application
const rootEl = document.querySelectorAll('[data-react-root]')[0];
rootEl.setAttribute('suppressHydrationWarning', 'true');

const getAppComponent = (store) => {
  const App = require('APP').default; // eslint-disable-line
  const reactElement = React.createElement(App);
  const provider = React.createElement(Provider, { store }, reactElement);
  store.replaceReducer(getReducer());
  return provider;
};

/**
 * Helper function that take the window.matchMedia results and returns a string key of
 * there is a match and another if it is not a match.
 */
const devideTypeCheck = (x, newVal, current) => {
  if (x.matches) {
    return newVal;
  }
  return current;
};

const render = (store) => {
  const App = getAppComponent(store);
  if (!isPuppeteer) {
    handleSSRFallbacks();
    let deviceType = 'desktop';

    /* Performa a mediaquery for screen width in javascript */
    let tabletCheck = window.matchMedia('(min-width: 415px) and (max-width: 899px)');
    let mobileCheck = window.matchMedia('(max-width: 415px)');
    deviceType = devideTypeCheck(tabletCheck, 'tablet', deviceType);
    deviceType = devideTypeCheck(mobileCheck, 'mobile', deviceType);
    /**
     * Detect if the rendering is done in scod or if the rendering is done on a iPad mini.
     * If that is the case we performa a clean rendering and in the other case we perform a
     * hydration. The reason we need to do a rendering for a iPad mini is that the iPad mini
     * send up in the wrong cache in scod which can not detect it as a tablet due to the userAgent.
     * Do this if:
     * - We are using a stelthIpad
     * - We have no initial state
     * - The device type has changed
     */
    if (
      typeof window.__INITIAL_STATE === 'undefined' ||
      (window.__INITIAL_STATE &&
        window.__INITIAL_STATE.modules &&
        window.__INITIAL_STATE.modules.App &&
        window.__INITIAL_STATE.modules.App.deviceType !== deviceType) ||
      isStealthIpad()
    ) {
      // Doing render instead of hydrate as below in order to keep the hot reloading working
      const root = ReactDOMClient.createRoot(rootEl);
      root.render(App);

      // window.STR = ReactServerDOM.renderToString(App, rootEl);
    } else {
      log.debug('Hydrating', new Date().getTime());
      // React 18 code - Create *and* render a root with hydration.
      ReactDOMClient.hydrateRoot(rootEl, App);
    }

    handleInitialStyling();
  } else {
    const root = ReactDOMClient.createRoot(rootEl);
    root.render(App);
  }
};

const storeInitialized = (store) => {
  store.dispatch({
    type: SessionActionTypes.INIT_APPLICATION,
  });
  localeSvc.init(store);
  siteLocaleSvc.init(store);
  render(store);
};

// require('APP').default; // eslint-disable-line
newStore(false, storeInitialized);
