import React from 'react';
import { Helmet } from 'react-helmet';
import stripHtmlTags from 'core/util/htmlTags';
import logger from 'score/logSvc';

const log = logger.getLogger('StructuredSEOData'); // eslint-disable-line

export const setGoogleAvailability = (status) => {
  switch (status) {
    case 'BUYABLE':
      return 'https://schema.org/InStock';
    case 'NOT_BUYABLE':
      return 'https://schema.org/OutOfStock';
    case 'PROLONGED_DELIVERY':
      return 'https://schema.org/BackOrder';
    default:
      break;
  }
};

export const compileSeoDataFromProduct = (product) => {
  const seoData = {
    name: product.name,
    description: product.metaDescription ? product.metaDescription : stripHtmlTags(product.summary),
    '@context': 'https://www.schema.org',
    '@type': 'Product',
    sku: product.code,
    offers: {
      price: Math.round(product.price),
      priceCurrency: product.currency,
      availability: setGoogleAvailability(product.status),
      url: typeof location !== 'undefined' ? location.origin + product.url : product.url,
      '@type': 'Offer',
    },
    brand: {
      '@type': 'Brand',
      name: 'ACME',
    },
  };
  if (product.images && product.images.medium && product.images.medium.length > 0) {
    seoData.image = product.images.medium.map((image) => image.url);
  }

  if (product.manufacturerProductNumber) {
    seoData.mpn = product.manufacturerProductNumber;
  }

  if (product.color) {
    seoData.color = product.color;
  }

  if (product.manufacturer) {
    seoData.brand = {
      '@type': 'Brand',
      name: product.manufacturer,
    };
  }

  return seoData;
};

export const StructuredSEOData = (p) => {
  if (!p.data) return null;
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(p.data)}
      </script>
    </Helmet>
  );
};

export default StructuredSEOData;
