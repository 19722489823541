import { setInLocalStore } from '@spa-core/redux/store';
import { mapItems, setupInitialState, setupMutations } from 'core/util/module-utils';
import { actions as messageActions } from 'eco/GlobalMessages/GlobalMessagesMod';
import each from 'lodash/each';
import React from 'react';
import { connect } from 'react-redux';
import logger from 'score/logSvc';
import net from 'score/networkSvc';
import { NAME as sessionReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'NavigationSvc';

// Modules data, this is the initial data for an instance
const initialState = {
  headerRoutes: [],
  footerRoutes: [{ nodeLinks: [] }, { nodeLinks: [] }],
  footerNotice: '',
  uniqueKeyCnt: 0,
  sitemap: {
    headerNodes: [],
    footerNodes: [{ nodes: [{ nodes: [] }, { nodes: [] }] }],
    coreNodes: {},
    db: {},
  },
  routes: [],
  cateNodeData: [],
};

let startState = {
  headerNodes: [],
  footerNodes: [{ nodes: [{ nodes: [] }, { nodes: [] }] }],
  routes: {},
  orderedRoutesArr: [],
  categoryNodeData: [],
  headerNodeList: [],
};

if (typeof window !== 'undefined' && typeof window.__NAV_SVC_DATA !== 'undefined') {
  startState = window.__NAV_SVC_DATA;
}
export const svcData = startState;
const log = logger.getLogger(name); // eslint-disable-line

export const findSection = (path, nav) => {
  let res;
  nav.forEach((item) => {
    if (item.url === path) {
      res = item;
    } else if (item.nodes.length > 0) {
      item.nodes.forEach((link) => {
        if (decodeURIComponent(link.url) === path) {
          res = item;
        } else {
          log.trace('💀 No Link Match', link.url, path);
        }
      });
    }
  });
  return res;
};

const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  // const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  const instance = mapItems(state, conf, ownProps.iid);
  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch) => ({
  fetchSitemap: (fetchSiteMapEntriesIfCampaignActiveInSession) => {
    dispatch(async (_disp) => {
      const sessionConfig = getReducer(sessionReducerName).sessionConfig;
      const navEndpointUrl = sessionConfig.urlPrefix + '/rest/v2/sitemap';
      const globalMessages = messageActions(_disp);

      // Only fetch routes if there are none
      if (Object.keys(svcData.routes).length < 1 || logger.cacheDisabled() || fetchSiteMapEntriesIfCampaignActiveInSession) {
        try {
          const result = await net.get(navEndpointUrl);
          _disp({ type: 'NAV_SVC_SITEMAP', sitemap: result, noAnalytics: true, sessionConfig, _disp });
        } catch (err) {
          globalMessages.addRestCallFailed(navEndpointUrl, 'GET', err);
        }
      }
    });
  },
  fetchCategoryNode: (nodeIds, sessionConfig) => {
    dispatch(async (_disp) => {
      const navEndpointUrl = `${sessionConfig.urlPrefix}/rest/v2/categories/nodes?codes=${nodeIds}`;
      const globalMessages = messageActions(_disp);

      // console.log(nodeIds);
      // Only fetch category node if there are none
      try {
        const result = await net.get(navEndpointUrl);
        _disp({ type: 'NAV_SVC_CATEGORY_NODE_DATA', cateNodeData: result });
      } catch (err) {
        globalMessages.addRestCallFailed(navEndpointUrl, 'GET', err);
      }
    });
  },
  updateSitemapWithNewEntry: (entry) => {
    dispatch(async (_disp) => {
      _disp({ type: 'NAV_SVC_SITEMAP_ADD_ENTRY', entry, noAnalytics: true });
    });
  },
  toggle(uid) {
    dispatch({ type: 'NAV_SVC_TOGGLE_NODE', uid });
  },
  expand(uid) {
    dispatch({ type: 'NAV_SVC_EXPAND_NODE', uid });
  },
});

const fetchCateNodeData = (nodeIds, sessionConfig, _disp) => {
  const action = actions(_disp);
  action.fetchCategoryNode(nodeIds, sessionConfig);
};

const traverseV2 = (nodeArray, routeObj) => {
  each(nodeArray, (o) => addNodeToRouteMap(o, routeObj));
};

const addNodeToRouteMap = (node, routeObj) => {
  if (node.url && !node.url.startsWith('/#')) {
    routeObj[node.url] = node; // eslint-disable-line
  }
  if (node.nodes) {
    traverseV2(node.nodes, routeObj);
  }
};

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  NAV_SVC_SITEMAP: (state, action) => {
    let cnt = 0;
    state.sitemap = {
      db: {},
    };
    const nodeList = [];
    if (action.sitemap) {
      const nodes = action.sitemap.map((n) => cloneNode(n));
      svcData.headerNodes = nodes.filter((n) => n.ntype === 'h');
      svcData.footerNodes = nodes.filter((n) => n.ntype === 'f');
      svcData.headerNodes.forEach((node) => nodeList.push(node.url.split('/').pop()));
      svcData.routes = {};
      traverseV2(nodes, svcData.routes);
      svcData.orderedRoutesArr = sortRoutes(svcData.routes);
      state.routes = svcData.orderedRoutesArr.map((v) => v[0]);
      svcData.headerNodeList = nodeList;
      fetchCateNodeData(nodeList, action.sessionConfig, action._disp);
      if (typeof window !== 'undefined') {
        window.navData = svcData;
        setInLocalStore('__NAV_SVC_DATA', svcData);
      }
    }
  },
  NAV_SVC_CATEGORY_NODE_DATA: (state, action) => {
    if (action.cateNodeData) {
      svcData.categoryNodeData = action.cateNodeData;
      state.cateNodeData = action.cateNodeData;
      if (typeof window !== 'undefined') {
        window.navData = svcData;
        setInLocalStore('__NAV_SVC_DATA', svcData);
      }
    }
  },
  NAV_SVC_SITEMAP_ADD_ENTRY: (state, action) => {
    if (!action.entry || !svcData || !svcData.routes) {
      return;
    }
    const entry = cloneNode(action.entry);
    addNodeToRouteMap(entry, svcData.routes);
    svcData.orderedRoutesArr = sortRoutes(svcData.routes);
    state.routes = svcData.orderedRoutesArr.map((v) => v[0]);
    if (typeof window !== 'undefined') {
      window.navData = svcData;
      setInLocalStore('__NAV_SVC_DATA', svcData);
    }
  },
};

const sortRoutes = (routes) => {
  return Object.entries(routes).sort((v1, v2) => {
    if (v1[1].priority === undefined) {
      v1[1].priority = 0;
    }
    if (v2[1].priority === undefined) {
      v2[1].priority = 0;
    }
    return v2[1].priority - v1[1].priority;
  });
};

const cloneNode = (node) => {
  const res = {
    title: node.title,
    metaTitle: node.mtitle,
    url: node.url,
    showOnlyInSideNav: node.showOnlyInSideNav || false,
    metaRobots: node.mrobots,
    exact: node.exact || false,
    priority: node.priority || 0,
    expanded: node.expanded || false,
    pageId: node.pid,
    description: node.desc,
    metaDescription: node.mdesc,
    keywords: node.kwords,
    template: node.tmpl,
    external: node.external || false,
    target: node.target,
    type: node.tmpl,
    showBreadCrumbsOnPage: node.showBCOnPage || false,
    ntype: node.ntype,
    uid: node.uid,
    sectionUid: node.secUid,
    parentUid: node.parentUid,
  };
  if (node.nodes && node.nodes.length > 0) {
    res.nodes = node.nodes.map((n) => cloneNode(n));
  } else {
    res.nodes = [];
  }
  res.items = res.nodes;
  return res;
};

/* eslint-enable */
// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  mutations: setupMutations(mutations, conf), // eslint-disable-line
};

// ################# RENDER  #################
class NavigationSvcMod extends React.Component {
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    return <div />;
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default connect(getters, actions)(NavigationSvcMod);
