export const NAME = 'categories'

export const ActionTypes = {
    FETCH_CATEGORY: `${NAME}/FETCH_CATEGORY`,
    FETCH_FILTERS: `${NAME}/FETCH_FILTERS`,
    SET_FETCHING_FILTER_CODE: `${NAME}/SET_FETCHING_FILTER_CODE`,
    SET_FILTERS: `${NAME}/SET_FILTERS`,
    SET_CATEGORY_DATA_IN_STORE: `${NAME}/SET_CATEGORY_DATA_IN_STORE`,
    CLEAR_CATEGORY_DATA: `${NAME}/CLEAR_CATEGORY_DATA`,
    SET_LOADING: `${NAME}/SET_LOADING`,
    SET_APPENDING_PRODUCTS: `${NAME}/SET_APPENDING_PRODUCTS`,
    SET_CATEGORY: `${NAME}/SET_CATEGORY`,
    SET_SELECTED_FACETS: `${NAME}/SET_SELECTED_FACETS`,
    SET_USER_LIMITS: `${NAME}/SET_USER_LIMITS`,
    SET_SORTING: `${NAME}/SET_SORTING`,
    TOGGLE_EXPANDED_NAVIGATIONTREE: `${NAME}/TOGGLE_EXPANDED_NAVIGATIONTREE`,
    FETCH_SUBCATEGORIES: `${NAME}/FETCH_SUBCATEGORIES`,
    FETCHED_SUBCATEGORIES: `${NAME}/FETCHED_SUBCATEGORIES`,
}

export enum FetchCategoryMode {
    APPEND = 'append',
    REFRESH = 'refresh',
}

export const CATEGORY_CACHE_TTL_SECONDS: number = 120
