import React from 'react';
import PropTypes from 'prop-types';
import { getThemeFactory } from '@ic-theme';
import logger from 'score/logSvc';
import classnames from 'classnames';
import cleanKey from 'core/util/cleanKey';

const name = 'USPWrapper';
const theme = getThemeFactory(name, {
  maximumItemsMobile: 2,
  maximumItemsIpad: 3,
});
const log = logger.getLogger(name); // eslint-disable-line

export default class USPWrapper extends React.Component {
  /**
   * propTypes
   * @property {string} children
   * @property {string} width
   */
  static get propTypes() {
    return {
      children: PropTypes.node,
    };
  }

  render() {
    const th = theme();
    const children = this.props.children || [];
    return (
      <div
        className={'ic-grid-center print:hidden e2e-ver-usp-wrapper smartEditComponent'}
        data-smartedit-component-id={cleanKey(this.props.SSRKey)}
        data-smartedit-component-uuid={cleanKey(this.props.elementUid)}
        data-smartedit-catalog-version-uuid={cleanKey(this.props.contentSlotCatalogVersion)}
        data-smartedit-component-type={cleanKey(this.props.componentParentType)}
      >
        <ul className="ic-col-12 ic-grid-center">
          {children.map((child, i) => {
            let classes = 'hidden lg:block';
            if (i < th.maximumItemsMobile) {
              // Show for all
              classes = '';
            } else if (i < th.maximumItemsIpad) {
              // Show for ipad
              classes = 'hidden md:block';
            }
            return (
              <div key={'usp-item-' + child.key} className={classnames(classes)}>
                {child}
              </div>
            );
          })}
        </ul>
      </div>
    );
  }
}
