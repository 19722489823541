import React from 'react';
import logger from 'score/logSvc';
import Link from 'ui/Link';
import classNames from 'classnames';
import * as cls from './Footer.styles';

const log = logger.getLogger('FooterLinksForDesktop',{}); // eslint-disable-line

const FooterLinksForDesktop = (p) => (
  <div className={classNames('w-full lg:px-4 lg:pb-6 hidden md:block', cls['footer-desktop'])}>
    {p.nodes ? (
      <ul className={classNames(cls['footer-nodes'], 'm-0 flex justify-between')}>
        {p.nodes.map((item) =>
          item.nodes.map((innerItem) => {
            if (!innerItem.uid) return null;
            return (
              <li key={'p_' + innerItem.uid} className={classNames(cls['individual-footer-node'], 'px-2')}>
                <h4 className={'font-bold my-4'}>{innerItem.title}</h4>
                <ul className={classNames(cls.Fb, 'm-0')} key={'ugh_' + innerItem.uid}>
                  {innerItem.nodes
                    ? innerItem.nodes
                        .filter((link) => link.url)
                        .map((link) => (
                          <li key={link.uid} className={'e2e-ver-url' + link.url.replaceAll('/', '-')}>
                            <Link to={link.url} external={link.external} target={link.target} linkTitle={link.title} />
                          </li>
                        ))
                    : null}
                  {innerItem.image ? (
                    <li className={cls['content-Fb']}>
                      <img alt={innerItem.image.altText} src={innerItem.image.url} />
                    </li>
                  ) : null}
                  {innerItem.description ? (
                    <li className={cls['content-Fb']}>
                      <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                    </li>
                  ) : null}
                </ul>
              </li>
            );
          })
        )}
      </ul>
    ) : null}
  </div>
);

export default FooterLinksForDesktop;
