import { ActionTypes } from './constants'
import { Action } from '../interfaces'
import { AppStore, SessionConfig, SetConfigPayload, CustomerTypeWarningDialog } from './interfaces'

const getWindowSessionConfig = (): SessionConfig => {
    const documentSessionConfig: any = window['sessionConf'] || {}
    Object.keys(window['sessionConf']).forEach((key: string) => {
        const value: any = documentSessionConfig[key]
        if (value === 'true') {
            documentSessionConfig[key] = true
        }
        if (value === 'false') {
            documentSessionConfig[key] = false
        }
    })
    return documentSessionConfig
}

export const INITIAL_STATE: AppStore = {
    promotions: {},
    viewedProductDetails: {},
    productInformationExpanded: true,
    productSpecificationExpanded: true,
    purchaseQuantity: {},
    previousIdentifiedGoogleAnalyticsLabel: undefined,
    sessionConfig: getWindowSessionConfig(),
    updatingSessionConfig: false,
    customerTypeWarningDialog: {
        showDialog: false,
        messageText: '',
        messageTitle: '',
        popupTitle: '',
        cancelText: '',
        logoutText: '',
        targetCountryUrl: '',
    },
    collapsablePanelsExpanded: {},
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): AppStore => {
    switch (type) {
        case ActionTypes.FETCHED_PROMOTIONS: {
            return {
                ...state,
                promotions: payload.promotions,
            }
        }
        case ActionTypes.SET_CONFIG: {
            const { sessionConfig, googleAnalyticsLabel }: SetConfigPayload = payload
            const toSet: any = {
                ...state.sessionConfig,
                ...sessionConfig,
            }
            const previousIdentifiedGoogleAnalyticsLabel: number =
                googleAnalyticsLabel || state.previousIdentifiedGoogleAnalyticsLabel
            return {
                ...state,
                sessionConfig: toSet,
                previousIdentifiedGoogleAnalyticsLabel,
            }
        }
        case ActionTypes.SET_UPDATING_SESSION_CONFIG:
            return {
                ...state,
                updatingSessionConfig: payload.value,
            }
        case ActionTypes.PRODUCT_DETAILS_VIEWED:
            return {
                ...state,
                viewedProductDetails: {
                    ...state.viewedProductDetails,
                    [payload.productCode]: true,
                },
            }
        case ActionTypes.PRODUCT_INFORMATION_EXPANDED:
            return {
                ...state,
                productInformationExpanded: payload.expanded,
            }
        case ActionTypes.PRODUCT_SPECIFICATION_EXPANDED:
            return {
                ...state,
                productSpecificationExpanded: payload.expanded,
            }
        case ActionTypes.SET_PURCHASE_QUANTITY:
            return {
                ...state,
                purchaseQuantity: {
                    ...state.purchaseQuantity,
                    [payload.productCode]: payload.quantity,
                },
            }
        case ActionTypes.SET_CUSTOMER_TYPE_CHANGE_WARNING: {
            const {
                showDialog,
                messageText,
                messageTitle,
                popupTitle,
                cancelText,
                logoutText,
                targetCountryUrl,
            }: CustomerTypeWarningDialog = payload

            return {
                ...state,
                customerTypeWarningDialog: {
                    showDialog,
                    messageText,
                    messageTitle,
                    popupTitle,
                    cancelText,
                    logoutText,
                    targetCountryUrl,
                },
            }
        }
        case ActionTypes.SET_COLLAPSABLE_PANEL_EXPANDED: {
            const { collapsablePanelId, expanded } = payload
            return {
                ...state,
                collapsablePanelsExpanded: {
                    ...state.collapsablePanelsExpanded,
                    [collapsablePanelId]: expanded,
                },
            }
        }
        default:
            return state
    }
}
